import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function BottomSnackBar(props) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        props.setOpen(false)
    }
    return (
        <Snackbar
            open={props.open}
            autoHideDuration={4000}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={props.type}
                sx={{ width: '100%' }}
            >
                {props.message}
            </Alert>
        </Snackbar>
    )
}
