import { useMutation } from '@apollo/client'

import { RIMUOVI_UTENTE, UTENTI, } from '../utils/api'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

import { useTranslation } from 'react-i18next'

export default function DeleteUserDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'users'])

    const [updateServer] = useMutation(RIMUOVI_UTENTE, {
        refetchQueries: [UTENTI],
    })

    function setDeletionOK(user) {
        setSnackBar('success', t('deleteuser.userdeleteddok', { ns: 'users', user: user.name.concat(" ", user.surname) }))
    }

    function setDeletionKO(user) {
        setSnackBar('error', t('deleteuser.userdeletedko', { ns: 'users', user: user.name.concat(" ", user.surname) }))
        onClose()
    }

    function setDeletionNotAuthorized() {
        setSnackBar('error', t('deleteuser.userdeletednotauthorized', { ns: 'users' }))
        onClose()
    }

    const handleDelete = async () => {
        try {
            await updateServer({
                variables: {
                    input: {
                        userId: selectedValue.id
                    }
                },
            })
            setDeletionOK(selectedValue)
        } catch (e) {
            console.error(e)
            if (e.message.includes('not authorized')) {
                setDeletionNotAuthorized()
            }
            else { setDeletionKO(selectedValue) }
        }
    }

    if (selectedValue) {
        return (
            < Dialog onClose={onClose} open={open} >
                <DialogTitle>
                    {t('deleteuser.deleteuser', {
                        ns: 'users',
                        user: selectedValue.name.concat(" ", selectedValue.surname)
                    })}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={onClose}>{t('button.cancel')}</Button>
                    <Button variant="contained" onClick={handleDelete}>
                        {t('button.yes')}
                    </Button>
                </DialogActions>
            </Dialog >
        )
    }
    else
        return <></>
}
