import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import excelfile from '../assets/mockupFiles/Modello domande quiz.xlsx'

import {
    CREA_DOMANDA,
    CREA_RISPOSTA,
    EDIT_DOMANDA,
    EDIT_RISPOSTA,
    TEST,
    ELIMINA_DOMANDA
} from '../utils/api'
import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    FormControl,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import ProgressBar from '../components/LoadingProgressBar'

import * as XLSX from 'xlsx'
import { Stack } from '@mui/system'

import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone'

export default function QuizCourseDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'courses'])

    const [buttonChoice, setButtonChoice] = useState('view')

    const handleSelectedButton = (event, newButtonChoice) => {
        setButtonChoice(newButtonChoice)
    }

    const [question, setQuestion] = useState()
    const [text, setText] = useState()
    const [questions, setQuestionAll] = useState()
    const [answers, setAnswers] = useState()

    const { error, data, loading: loading_query, refetch, } = useQuery(TEST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (data && !loading_query && !error) {
            setQuestion(
                data?.courses.filter(
                    (course) => course.id === selectedValue.id
            )[0]?.test?.questions[0]
            )
            setText(data?.courses.filter(
                (course) => course.id === selectedValue.id
            )[0]?.test?.questions[0]?.text)
            setQuestionAll(
                data?.courses.filter(
                    (course) => course.id === selectedValue.id
                )[0]?.test?.questions
            )
            setAnswers(
                data?.courses.filter(
                    (course) => course.id === selectedValue.id
                )[0]?.test?.questions[0]?.allAnswers
            )
        }
    }, [data, error, loading_query, selectedValue.id])

    const [createQ] = useMutation(CREA_DOMANDA)

    const [createA] = useMutation(CREA_RISPOSTA)

    const [editA] = useMutation(EDIT_RISPOSTA, {
        refetchQueries: [TEST],
    })

    const [editQ] = useMutation(EDIT_DOMANDA, {
        refetchQueries: [TEST],
    })

    const [deleteQ] = useMutation(ELIMINA_DOMANDA)

    const [qa, setQA] = useState(null)
    const [progress, setProgress] = useState(10)
    const [loading, setLoading] = useState(false)
    const [fileReady, setFileReady] = useState(false)

    const onChange = (e) => {
        setLoading(true)
        setFileReady(false)
        setProgress(10)
        const [file] = e.target.files
        const reader = new FileReader()

        reader.onprogress = (evt) => {
            var progress = parseInt((evt.loaded / evt.total) * 100, 10)
            setProgress(progress)
        }

        reader.onload = (evt) => {
            const bstr = evt.target.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            const data = XLSX.utils.sheet_to_json(ws, { blankrows: false })
            setQA(data)
            setFileReady(true)
            setLoading(false)
        }
        reader.readAsBinaryString(file)
    }

    function setEditOK() {
        setSnackBar('success', t('quiz.quizeditedok', { ns: 'courses' }))
    }

    function setEditKO() {
        setSnackBar('error', t('quiz.quizeditedko', { ns: 'courses' }))
    }

    function questionAlreadyExists(questionText) {
        for (let i = 0; i < questions?.length; i++) {
            if (questions[i].text === questionText) {
                return questions[i].id
            }
        }
        return null
    }

    function answerAlreadyExists(answerText, questionId) {
        let current_anwers = questions?.filter((question) => question.id === questionId)[0]?.allAnswers
        for (let i = 0; i < current_anwers?.length; i++) {
            if (current_anwers[i].text === answerText) {
                return current_anwers[i].id
            }
        }
        return null
    }

    const deleteAllQuiz = async () => {
        //Remove all previous quiz
        for (let i = 0; i < questions.length; i ++)
            {
                try {
                    let res = await deleteQ({
                        variables: { questionId: questions[i].id },
                    })
                    if (res.data) console.log("Deleted question")
                    else console.error("Error on deletion")
        
                } catch (e) {
                    console.error(e)
                    setEditKO()
                    CloseAndRefetch()
                }
            }
            setEditOK()
            CloseAndRefetch()
    }

    const handleQuiz = async () => {
        if (fileReady) {
            let idQuestion = null
            let response
            for (let i = 0; i < qa.length; i++) {
                let d = qa[i]
                if (d?.QN && d?.Q) { //è una domanda
                    if (questionAlreadyExists(d.Q)) { //la domanda esiste già
                        idQuestion = questionAlreadyExists(d.Q)
                    }
                    else {
                        try {
                            response = await createQ({
                                variables: {
                                    courseId: selectedValue.courseId,
                                    newQuestion: d?.Q,
                                },
                            })
                            if (response.errors) {
                                setEditKO()
                                CloseAndRefetch()
                                return
                            }
                            idQuestion = response.data.addQuestion2.id
                        } catch (e) {
                            console.error(e)
                            setEditKO()
                            CloseAndRefetch()
                            return
                        }
                    }
                }

                if (d?.AN && d?.A) { //è una risposta
                    if (answerAlreadyExists(d.A, idQuestion)) {
                        console.log("Risposta esiste già")
                        continue; //la risposta esiste già
                    }
                    else {
                        try {
                            response = await createA({
                                variables: {
                                    questionId: idQuestion,
                                    newAnswer: d?.A,
                                    correct: d['V/F'] === 'V',
                                },
                            })
                            if (response.errors) {
                                setEditKO()
                                CloseAndRefetch()
                                return
                            }
                        } catch (e) {
                            console.error(e)
                            setEditKO()
                            CloseAndRefetch()
                            return
                        }
                    }
                }
            }
            setEditOK()
            CloseAndRefetch()
        } else return
    }

    const editAnswer = async (event) => {
        const an = [...answers]
        let id =
            event.target.id === ''
                ? event.target.parentNode.id
                : event.target.id
        try {
            let res = await editA({
                variables: {
                    answerId: an[id].id,
                    newText: an[id].text,
                    correct: an[id].correct,
                },
            })
            if (res.data) {
                setSnackBar(
                    'success',
                    t('answer.answereditedok', { ns: 'courses' })
                )
            } else
                setSnackBar(
                    'error',
                    t('answer.answereditedko', { ns: 'courses' })
                )
        } catch (e) {
            console.error(e)
            setSnackBar('error', t('answer.answereditedko', { ns: 'courses' }))
        }
    }

    const editQuestion = async (event) => {
        try {
            let res = await editQ({
                variables: { questionId: question.id, newText: text },
            })
            if (res.data) {
                setSnackBar(
                    'success',
                    t('question.questioneditedok', { ns: 'courses' })
                )
            } else
                setSnackBar(
                    'error',
                    t('question.questioneditedko', { ns: 'courses' })
                )
        } catch (e) {
            console.error(e)
            setSnackBar(
                'error',
                t('question.questioneditedko', { ns: 'courses' })
            )
        }
    }

    const displayExcel = () => {
        let d = ''
        for (let i = 0; i < qa.length; i++) {
            const { QN, Q, AN, A } = qa[i]
            if (QN && Q) {
                d = d.concat('\n')
                d = d.concat(Q)
            }
            if (AN && A) {
                d = d.concat('\n\t')
                d = d.concat(A)
                if (qa[i]['V/F'] === 'V') d = d.concat(' V')
                else d = d.concat(' F')
            }
        }
        return d
    }

    const [visible, setVisible] = useState(true)

    const handleChange = (event) => {
        setQuestion(event.target.value)
        setText(event.target.value.text)
        setAnswers(event.target.value.allAnswers)
    }

    const handleATextChange = (event) => {
        const an = [...answers]
        an[event.target.id].text = event.target.value
        setAnswers(an)
    }

    const handleQTextChange = (event) => {
        setText(event.target.value)
    }

    const handleACorrectChange = (event) => {
        const an = [...answers]
        an[event.target.id].correct = event.target.checked
        setAnswers(an)
    }

    const CloseAndRefetch = () => {
        onClose()
        setFileReady(false)
        refetch()
    }

    if (loading_query) {
        return <>Loading...</>
    }
    return (
        <Dialog onClose={CloseAndRefetch} open={open}>
            <DialogTitle>
                {t('quiz.editquiz', {
                    ns: 'courses',
                    course: data?.courses.filter(
                        (course) => course.id === selectedValue.id
                    )[0].name,
                })}
            </DialogTitle>
            <DialogContent>
                <ToggleButtonGroup
                    size="small"
                    color="info"
                    value={buttonChoice}
                    exclusive
                    onChange={handleSelectedButton}
                    aria-label="Platform"
                    varinat="outlined"
                    sx={{ marginBottom: '2%' }}
                >
                    <ToggleButton onClick={() => setVisible(true)} value="view">
                        {t('quiz.viewquiz', { ns: 'courses' })}
                    </ToggleButton>
                    <ToggleButton
                        onClick={() => setVisible(false)}
                        value="upload"
                    >{t('quiz.loadquiz', { ns: 'courses' })}
                    </ToggleButton>
                    <ToggleButton
                        onClick={() => deleteAllQuiz()}
                        value="delete"
                    >{t('quiz.deletequiz', { ns: 'courses' })}
                    </ToggleButton>
                </ToggleButtonGroup>
                <Collapse in={visible}>
                    {question === undefined ? (
                        <Typography>
                            {t('quiz.noquiz', { ns: 'courses' })}
                        </Typography>
                    ) : (
                        <Stack sx={{ alignItems: 'left' }} spacing={1}>
                            <Stack direction="row" sx={{ alignItems: 'end' }} spacing={2}>
                                <FormControl sx={{ pt: 2, minWidth: '30%' }} size="small">
                                    <Select
                                        defaultValue={
                                            data?.courses.filter(
                                                (course) =>
                                                    course.id === selectedValue.id
                                            )[0]?.test?.questions[0]
                                        }
                                        variant="filled"
                                        onChange={handleChange}
                                    >
                                        {questions?.map((question, index) => (
                                            <MenuItem
                                                key={index + ' q'}
                                                value={question}
                                            >
                                                {"Question " + (index + 1)}
                                                {/*{question.text}*/}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    sx={{ width: '80%' }}
                                    variant="standard"
                                    value={text}
                                    onChange={handleQTextChange}
                                    multiline
                                >
                                </TextField>
                                <IconButton onClick={editQuestion}>
                                    {' '}
                                    <FileUploadTwoToneIcon />
                                </IconButton>
                            </Stack>
                            {answers?.map((answer, index) => {
                                return (
                                    <Stack
                                        direction="row"
                                        sx={{ justifyContent: 'space-between' }}
                                        key={index + ' a'}
                                    >
                                        <TextField
                                            sx={{ width: '80%' }}
                                            id={String(index)}
                                            variant="standard"
                                            value={answer.text}
                                            onChange={handleATextChange}
                                            multiline
                                        />
                                        <input
                                            type="checkbox"
                                            checked={answer.correct}
                                            id={index}
                                            onChange={handleACorrectChange}
                                        />
                                        <IconButton
                                            id={index}
                                            onClick={editAnswer}
                                        >
                                            {' '}
                                            <FileUploadTwoToneIcon id={index} />
                                        </IconButton>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    )}
                </Collapse>
                <Collapse in={!visible}>
                    <Stack spacing={1}>
                        <Typography variant="caption" sx={{ width: '100%', textAlign: 'left' }} gutterBottom>{t('question.uploadexcel', { ns: 'courses' })}
                            <a href={excelfile} download="quiz_mockup.xlsx">quiz_mockup.xlsx</a></Typography>
                        <input type="file" onChange={onChange} />
                        {loading && <ProgressBar progress={progress} />}
                        {fileReady && (
                            <Typography
                                variant="body1"
                                style={{ whiteSpace: 'pre-line' }}
                            >
                                {' '}
                                {displayExcel()}
                            </Typography>
                        )}
                    </Stack>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={CloseAndRefetch}>{t('button.cancel')}</Button>
                {fileReady && (
                    <Button
                        variant="contained"
                        onClick={handleQuiz}
                        disabled={!fileReady}
                    >
                        {t('button.modify')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
