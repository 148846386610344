import { useTranslation } from 'react-i18next'

import InputAutocomplete from './AutocompleteBasic'

export default function Lesson(props) {
    const [t] = useTranslation('common', { keyPrefix: 'lesson' })

    function isOptionEqualToValue(option, value) {
        return option.name === value.name
    }

    return (
        <InputAutocomplete
            value={props.value}
            defaultValue={props.defaultValue}
            loading={false}
            optionlabel={(option) =>
                option.name
            }
            isOptionEqualToValue={isOptionEqualToValue}
            required={true}
            multiple={false}
            setErrorRequired={props.setError}
            list_names={props.list}
            freeSolo={false}
            setValue={props.setValue}
            error_required={props.error}
            t={t}
        />
    )
}
