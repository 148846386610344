import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import {
    AGGIUNGI_UTENTE_A_GRUPPO,
    GRUPPI,
    RIMUOVI_UTENTE_DA_GRUPPO,
    UTENTI,
} from '../utils/api'
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    FormControlLabel,
    List,
    TextField,
    Stack,
    ListItem,
    Toolbar,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { dynamicSort } from '../utils/utils'

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'

export default function EditUsersinGroupsDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'departments'])

    const [users, setUsers] = useState([])

    const [userQuery, setUserQuery] = useState('')

    const [addUser] = useMutation(AGGIUNGI_UTENTE_A_GRUPPO, {
        refetchQueries: [GRUPPI],
    })
    const [removeUser] = useMutation(RIMUOVI_UTENTE_DA_GRUPPO, {
        refetchQueries: [GRUPPI],
    })

    const { data: datac, loading: loading_queryc } = useQuery(UTENTI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (datac?.users) {
            setUsers(datac.users.sort(dynamicSort('name')).map((user) => {
                const groups = user.userGroups.map((group) => {
                    return group.id
                })
                user.OldGroup = groups.includes(selectedValue.id)
                user.NewGroup = groups.includes(selectedValue.id)
                return user
            }))
        }
    }, [datac, selectedValue.id])

    function setEditOK(name) {
        setSnackBar('success', t('departments.groupeditedok', { ns: 'departments', group: name }))
    }

    function setEditKO(name) {
        setSnackBar('error', t('departments.groupeditedko', { ns: 'departments', group: name }))
    }

    const handleEdit = async () => {
        datac.users.forEach(async (user) => {
            if (user.NewGroup === user.OldGroup) { //non ci sono cambiamenti
                return;
            }
            else {
                //user should be added to group
                if (user.NewGroup) {
                    try {
                        await addUser({
                            variables: {
                                input: {
                                    userId: user.id,
                                    groupIds: [selectedValue.id],
                                },
                            },
                        })
                    } catch (e) {
                        console.error(e)
                        setEditKO()
                    }
                }
                else //user should be removed from group
                {
                    try {
                        await removeUser({
                            variables: {
                                input: {
                                    userId: user.id,
                                    groupIds: [selectedValue.id],
                                },
                            },
                        })
                    } catch (e) {
                        console.error(e)
                        setEditKO(selectedValue.name)
                    }
                }
            }
            setEditOK(selectedValue.name)
        });
    }

    function updateRows(event, user) {
        user.NewGroup = event.target.checked
    }

    if (loading_queryc) {
        return <>Loading...</>
    }

    return (
        <Dialog onClose={() => {
            setUserQuery('');
            onClose()
        }} open={open}>
            <DialogTitle>
                {t('departments.editusers', { ns: 'departments' }) +
                    ' ' +
                    selectedValue.name}
            </DialogTitle>
            <DialogContent>
                <Stack sx={{ alignItems: 'center' }}>
                    <AppBar position='sticky' sx={{ backgroundColor: "white", boxShadow: "none" }} >
                        <Toolbar>
                            <TextField placeholder={t('users.userquerysearch', { ns: 'users', })}
                                size='small'
                                onChange={(event) => {
                                    setUserQuery(event.target.value);
                                }}

                                sx={{ width: '100%', paddingBottom: '2%', }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchTwoToneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Toolbar>
                    </AppBar>
                    <List sx={{ width: '100%', maxWidth: 300 }}>
                        {users.map((user, index) => (
                            (userQuery === '' ||
                                user.name.concat(" ", user.surname)
                                    .toLowerCase()
                                    .includes(userQuery.toLowerCase())) &&
                            <ListItem sx={{ display: 'block' }} key={user.id + index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={user.OldGroup}
                                            onChange={(e) => updateRows(e, user)}
                                        />
                                    }
                                    label={user.name + ' ' + user.surname}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setUserQuery('');
                    onClose()
                }}>
                    {t('button.cancel', { ns: 'common' })}
                </Button>
                <Button variant="contained" onClick={handleEdit}>
                    {t('button.modify', { ns: 'common' })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}