import { NavLink } from 'react-router-dom'

export function TopBarButton(props) {
    const Button = {
        text: props.text,
        urn: props.urn,
    }

    return (
        <NavLink
            className={({ isActive }) =>
                isActive ? 'topbar-button-selected' : 'topbar-button'
            }
            to={Button.urn}
        >
            {Button.text}
        </NavLink>
    )
}

export default TopBarButton
