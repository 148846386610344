import React, { useCallback, useState } from 'react'

import TopBarButton from './TopBarButton'
import TopBarMenu from './TopBarMenu'
import { locales } from '../../utils/locale'

import LanguageSwitcher from '../LanguageSwitcher'

import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/img/logo-small.png'

import { MdExitToApp, MdPerson } from 'react-icons/md'

import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'

import { useTranslation } from 'react-i18next'

import {
    AppBar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material'

import { MEDIUM_SCREEN_BREAKPOINT, useWindowSize } from '../../utils/utils'

const SmallScreensTopBar = (props) => {
    const [t] = useTranslation('common')

    const [drawerOpen, setDrawerOpen] = useState(false)

    const toggleDrawer = useCallback(() => {
        setDrawerOpen((prevOpen) => !prevOpen)
    }, [])

    return (
        <React.Fragment>
            <AppBar sx={{ backgroundColor: '#fafafa', color: '#000000' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link
                        to="/"
                        style={{
                            flexGrow: 1,
                            textDecoration: 'none',
                            color: 'inherit',
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItems: 'center' }}
                        >
                            <img
                                src={logo}
                                alt="ABB Logo"
                                style={{ height: 35, marginBottom: 5 }}
                            />
                            <Typography variant="h6">{t('appName')}</Typography>
                        </Stack>
                    </Link>
                    {props.user ? (
                        <TopBarMenu
                            title="menu"
                            buttons={[
                                {
                                    text: t('topBar.dropdown.profile'),
                                    urn: '/profile',
                                    icon: <MdPerson size={20} />,
                                },
                                {
                                    text: t('topBar.dropdown.signOut'),
                                    urn: '/logout',
                                    icon: <MdExitToApp size={20} />,
                                },
                            ]}
                            user={props.user}
                        />
                    ) : null}
                </Toolbar>
            </AppBar>
            <Drawer
                id="drawer"
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer}
            >
                <Box
                    sx={{ width: 200, height: '100%' }}
                    role="presentation"
                    onKeyDown={toggleDrawer}
                >
                    <Stack sx={{ height: '100%' }} spacing={2}>
                        <Toolbar></Toolbar>
                        {props.buttons && (
                            <List onClick={toggleDrawer}>
                                <NavLink
                                    key="/"
                                    to="/"
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <ListItem className="drawer-link" button>
                                        <ListItemIcon>
                                            <HomeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('home')} />
                                    </ListItem>
                                </NavLink>
                                {props.buttons.map((button, index) => (
                                    <NavLink
                                        key={button.urn}
                                        to={button.urn}
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <ListItem
                                            className="drawer-link"
                                            button
                                        >
                                            {button.icon && (
                                                <ListItemIcon>
                                                    {button.icon}
                                                </ListItemIcon>
                                            )}
                                            <ListItemText
                                                primary={button.text}
                                            />
                                        </ListItem>
                                    </NavLink>
                                ))}
                                <Divider />
                            </List>
                        )}
                        <div style={{ maxWidth: 300, paddingLeft: 20 }}>
                            <LanguageSwitcher locales={locales} />
                        </div>
                    </Stack>
                </Box>
            </Drawer>
        </React.Fragment>
    )
}

export function TopBar(props) {
    const [t] = useTranslation('common')

    const [width] = useWindowSize()
    if (width <= MEDIUM_SCREEN_BREAKPOINT) {
        return <SmallScreensTopBar {...props} />
    }

    return (
        <nav id="top-bar">
            <Link to="/" id="logo">
                <img src={logo} alt="ABB Logo" style={{ height: '90%' }} />
            </Link>
            <div className="topbar-lang-switcher">
                <LanguageSwitcher locales={locales} />
            </div>
            {props.buttons ? (
                <div className="topbar-button-container">
                    {props.buttons.map((button) => (
                        <TopBarButton
                            title="menu"
                            text={button.text}
                            urn={button.urn}
                            key={button.text + button.urn}
                        />
                    ))}
                </div>
            ) : null}
            {props.user ? (
                <TopBarMenu
                    width={width}
                    title="menu"
                    buttons={[
                        {
                            text: t('topBar.dropdown.profile'),
                            urn: '/profile',
                            icon: <MdPerson size={20} />,
                        },
                        {
                            text: t('topBar.dropdown.signOut'),
                            urn: '/logout',
                            icon: <MdExitToApp size={20} />,
                        },
                    ]}
                    user={props.user}
                />
            ) : null}
        </nav>
    )
}

export default TopBar
