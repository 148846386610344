import { Chip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { TAGS } from '../utils/api'

import InputAutocomplete from '../components/AutocompleteBasic'
import { useEffect, useRef, useState } from 'react'

export default function InputTags(props) {
    const [t] = useTranslation(['common'], { keyPrefix: 'tags' })

    const [listTagsNames, setListTagsNames] = useState([''])

    const autoC = useRef(null);

    const { data, loading } = useQuery(TAGS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (data) {
            let list_tags_names = data?.tags.map((tag) => tag.name)
            setListTagsNames(list_tags_names)
        }
    }, [data])

    function resetCaption() {
        document.getElementById('tagCaption').style.color = 'black'
    }

    function highlightCaption() {
        document.getElementById('tagCaption').style.color = 'red'
        setTimeout(resetCaption, 5000);
    }

    function isTagCorrect(tagString) {
        var specialChars = /[ !@#$%^&*()_+=[\]{};':"\\|,.<>/?]+/;
        var tagStringLen = tagString.length

        return !specialChars.test(tagString) && tagStringLen >= 2 && tagStringLen <= 50
    }

    function keyDown(e) {
        if (e.key === 'Enter') {
            if (!isTagCorrect(e.target.value)) {
                e.stopPropagation();
                const close = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]; //get clear button of the tag autocomplete
                close.click()
                highlightCaption()
            }
        }
    }

    function renderTags(value, getTagProps) {
        return value.map((option, index) => {
            return (
                <div key={'chip' + index}>
                    <Chip
                        variant="outlined"
                        label={'#'.concat(option)}
                        {...getTagProps({ index })}
                    />
                    {props.setTags(
                        value
                    )}
                </div>
            )
        })
    }

    return (
        <>
            <InputAutocomplete
                defaultValue={props.defaultValue}
                loading={loading}
                optionlabel={(option) => option}
                required={true}
                multiple={true}
                setErrorRequired={props.setErrorTags}
                list_names={listTagsNames}
                freeSolo={true}
                render_Function={renderTags}
                setValue={props.setTags}
                error_required={props.error_tags}
                t={t}
                keyDown={keyDown}
                autoC={autoC}
            />
            <Typography variant="caption" sx={{ width: '100%', textAlign: 'left', color: 'black' }} id='tagCaption'>
                {t('caption')}
            </Typography>
        </>
    )
}