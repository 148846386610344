import { Autocomplete, Checkbox, TextField } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

import { useTranslation } from 'react-i18next'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function InputAutocomplete(props) {
    const [t] = [props.t]
    const [tg] = useTranslation('common')

    const handleChangeInput = (e) => {
        props.setErrorRequired(false)
    }

    function renderOption(option) {
        return props.optionlabel(option)
    }

    return (
        <Autocomplete
            fullWidth
            noOptionsText={tg('messages.nooptions')}
            disabled={props.loading}
            multiple={props.multiple}
            value={props.value}
            ref={props.autoC}
            id="autocomplete"
            defaultValue={props.defaultValue}
            isOptionEqualToValue={props.isOptionEqualToValue}
            //Values that can be selected
            options={props.list_names}
            freeSolo={props.freeSolo}
            //Render selected entries
            renderTags={(value, getTagProps) =>
                props.render_Function(value, getTagProps)
            }
            //Reset output value when deleting all entries
            onChange={(event, newValue) => {
                props.setErrorRequired(false)
                //if (newValue.length === 0) { props.setValue(newValue) }
                //if (props.render_Function === undefined || newValue.length === 0) {

                props.setValue(newValue)
            }}
            getOptionLabel={(option) => renderOption(option)}
            //Render the possibile options
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        onChange={(e) => handleChangeInput(e)}
                    />
                    {renderOption(option)}
                </li>
            )}
            //Render the text box
            renderInput={(params) => (
                <TextField
                    fullWidth
                    required={props.required}
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: props.keyDown //used to remove tags that don't follow the requirements
                    }}
                    label={t('title')}
                    placeholder={t('tip')}
                    onChange={(e) => handleChangeInput(e)}
                    error={!!props.error_required && props.required}
                    helperText={
                        props.error_required && props.required
                            ? tg('messages.errorrequired')
                            : ''
                    }
                />
            )}
        />
    )
}
