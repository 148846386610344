import { gql } from '@apollo/client'

export const BACKEND_URI = process.env.REACT_APP_BACKEND_URI
export const ENVIRONMENT = process.env.NODE_ENV
export const API_URI = `${BACKEND_URI}/graphql`
export const VIDEOS_BASE_URI = `${BACKEND_URI}/static/video/`
export const VIDEOS_UPLOAD_URI = `${BACKEND_URI}/static/upload/`
export const FILES_BASE_URI = `${BACKEND_URI}/static/file/`

export const CREA_CORSO = gql`
    mutation CREA_CORSO(
        $name: String!
        $courseId: String!
        $description: String
        $tags: [String]!
    ) {
        createCourse(
            input: {
                name: $name
                description: $description
                courseId: $courseId
                tags: $tags
            }
        ) {
            id
            viewableBy {
                id
                name
            }
        }
    }
`
export const CREA_LEZIONE = gql`
    mutation CREA_LEZIONE(
        $name: String!
        $courseId: ID!
        $description: String
        $lessonId: String!
        $level: String!
        $language: String!
        $tags: [String]!
        $teachers: [TeacherInput]!
        $requirements: [RequirementInput]
        $usefulLinks: [LinkInput]
    ) {
        addLessonToCourse(
            input: {
                name: $name
                description: $description
                courseId: $courseId
                lessonId: $lessonId
                level: $level
                language: $language
                tags: $tags
                teachers: $teachers
                requirements: $requirements
                usefulLinks: $usefulLinks
            }
        ) {
            id
            lessonId
        }
    }
`
export const CREA_INSEGNANTE = gql`
    mutation CREA_INSEGNANTE($name: String!, $surname: String!) {
        addTeacher(input: { name: $name, surname: $surname }) {
            id
            name
            surname
        }
    }
`

export const CORSI = gql`
    query CORSI {
        courses {
            id
            courseId
            name
            description
            tags {
                id
                name
            }
            lessons {
                lessonId
                name
                id
                level
                description
                tags {
                    name
                }
                requirements {
                    text
                    description
                }
                usefulLinks {
                    name
                    url
                }
                teachers {
                    id
                    name
                    surname
                }
                video {
                    name
                    uuid
                    thumbnailURL
                    formats {
                        extension
                    }
                }
            }
            viewableBy {
                id
                name
            }
        }
    }
`

export const TEST = gql`
    query TEST {
        courses {
            id
            courseId
            name
            test {
                id
                questions {
                    id
                    text
                    allAnswers {
                        id
                        text
                        correct
                    }
                }
            }
        }
    }
`

export const LEZIONI = gql`
    query LEZIONI($courseId: ID!) {
        course(input: { id: $courseId }) {
            lessons {
                name
            }
        }
    }
`

export const TAGS = gql`
    query TAGS {
        tags {
            name
        }
    }
`

export const GRUPPI = gql`
    query GRUPPI {
        groups {
            id
            name
            permissions {
                id
                name
            }
        }
    }
`
export const UTENTI = gql`
    query UTENTI {
        users {
            id
            name
            surname
            email
            userGroups {
                id
                name
            }
        }
    }
`
export const PERMESSI = gql`
    query PERMESSI {
        permissions {
            id
            name
        }
    }
`

export const INSEGNANTI = gql`
    query INSEGNANTI {
        teachers {
            id
            name
            surname
        }
    }
`
export const ELIMINA_CORSO = gql`
    mutation ELIMINA_CORSO($courseId: ID!) {
        removeCourse2(courseId: $courseId) {
            id
            courseId
            name
            description
            tags {
                id
                name
            }
            lessons {
                name
                id
                video {
                    uuid
                    thumbnailURL
                }
            }
            viewableBy {
                id
                name
            }
        }
    }
`
export const MODIFICA_CORSO = gql`
    mutation MODIFICA_CORSO(
        $id: ID!
        $courseId: String
        $name: String
        $description: String
        $tags: [String]
        $viewableBy: [Int!]
    ) {
        editCourse(
            input: {
                id: $id
                courseId: $courseId
                name: $name
                description: $description
                tags: $tags
                viewableBy: $viewableBy
            }
        ) {
            id
            name
        }
    }
`

export const MODIFICA_LEZIONE = gql`
    mutation MODIFICA_LEZIONE($input: EditLessonInput!) {
        editLesson(input: $input) {
            id
        }
    }
`

export const ELIMINA_LEZIONE = gql`
    mutation ELIMINA_LEZIONE($lessonId: ID!) {
        removeLesson(lessonId: $lessonId) {
            id
        }
    }
`
export const CREA_DOMANDA = gql`
    mutation CREA_DOMANDA($courseId: ID!, $newQuestion: String!) {
        addQuestion2(courseId: $courseId, newQuestion: $newQuestion) {
            id
        }
    }
`
export const CREA_RISPOSTA = gql`
    mutation CREA_RISPOSTA(
        $questionId: ID!
        $newAnswer: String!
        $correct: Boolean!
    ) {
        addAnswer(
            questionId: $questionId
            newAnswer: $newAnswer
            correct: $correct
        ) {
            id
        }
    }
`
export const EDIT_RISPOSTA = gql`
    mutation EDIT_RISPOSTA(
        $answerId: ID!
        $newText: String!
        $correct: Boolean!
    ) {
        editAnswer(answerId: $answerId, newText: $newText, correct: $correct) {
            id
        }
    }
`
export const EDIT_DOMANDA = gql`
    mutation EDIT_DOMANDA($questionId: ID!, $newText: String!) {
        editQuestion(questionId: $questionId, newText: $newText) {
            id
        }
    }
`
export const ELIMINA_DOMANDA = gql`
    mutation ELIMINA_DOMANDA($questionId: ID!) {
        deleteQuestion(questionId: $questionId) 
        {id}
    }
`

export const ELIMINA_RISPOSTA = gql`
    mutation ELIMINA_RISPOSTA($answerId: ID!) {
        deleteAnswer(answerId: $answerId)
        {id}
    }
`

export const CREA_GRUPPO = gql`
    mutation CREA_GRUPPO($input: CreateUserGroupInput!) {
        addUserGroup(input: $input) {
            id
            name
        }
    }
`
export const ELIMINA_GRUPPO = gql`
    mutation ELIMINA_GRUPPO($input: DeleteUserGroupInput!) {
        deleteUserGroup(input: $input)
    }
`
export const MODIFICA_GRUPPO = gql`
    mutation MODIFICA_GRUPPO($input: EditUserGroupInput!) {
        editUserGroup(input: $input) {
            id
        }
    }
`
export const AGGIUNGI_UTENTE_A_GRUPPO = gql`
    mutation AGGIUNGI_UTENTE_A_GRUPPO($input: AddUserGroupsToUserInput!) {
        addUserGroupsToUser(input: $input) {
            id
        }
    }
`
export const RIMUOVI_UTENTE_DA_GRUPPO = gql`
    mutation RIMUOVI_UTENTE_DA_GRUPPO($input: RemoveUserGroupsFromUserInput!) {
        removeUserGroupsFromUser(input: $input) {
            id
        }
    }
`
export const CREA_UTENTE = gql`
    mutation CREA_UTENTE($input: InsertUserInput!) {
        insertUser(input: $input) {
            id
            name
            surname
            email
        }
    }
`
export const RIMUOVI_UTENTE = gql`
    mutation RIMUOVI_UTENTE($input: DeleteUserInput!) {
        deleteUser(input: $input) 
    }
`