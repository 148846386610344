import { useEffect, useState } from 'react'

import {
    Box,
    Button,
    InputAdornment,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material'

import {
    UTENTI,
} from '../utils/api'

import { dynamicSort } from '../utils/utils'

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'

import { useTranslation } from 'react-i18next'

import { useQuery } from '@apollo/client'

import BottomSnackBar from '../components/BottomSnackBar'
import EditUserDialog from '../dialogs/EditUserDialog'
import AddUserDialog from '../dialogs/AddUserDialog'
import AddUserFileDialog from '../dialogs/AddUserFileDialog'
import PersonAddAlt1TwoToneIcon from '@mui/icons-material/PersonAddAlt1TwoTone'
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';

export default function Users() {
    const [t] = useTranslation(['common', 'users'])

    const [users, setUsers] = useState([])

    const [userQuery, setUserQuery] = useState('')

    const [open, setOpen] = useState(false)
    const [type, setType] = useState('info')
    const [message, setMessage] = useState('')

    const [isAddUserOpen, setIsAddUserOpen] = useState(false)
    const [isAddUserFileOpen, setIsAddUserFileOpen] = useState(false)

    const { data: datac, loading: loading_queryc, refetch } = useQuery(UTENTI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (datac?.users) {
            setUsers(datac.users.sort(dynamicSort('name')))
        }
    }, [datac])

    function setSnackBar(type, message) {
        setOpen(true)
        setType(type)
        setMessage(message)
    }

    if (loading_queryc) {
        return <>Loading...</>
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                overflowX: 'auto',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Toolbar />

            <AddUserDialog
                setSnackBar={setSnackBar}
                onClose={() => setIsAddUserOpen(false)}
                open={isAddUserOpen}
            />
            <AddUserFileDialog
                setSnackBar={setSnackBar}
                onClose={() => {setIsAddUserFileOpen(false); refetch()}}
                open={isAddUserFileOpen}
            />
            <Stack sx={{ alignItems: 'center' }} spacing={2}>
                <Typography
                    variant="h1"
                    sx={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}
                >
                    {t('users.title', { ns: 'users' })}
                </Typography>

                <Stack direction="row" marginTop={0} spacing={2} sx={{ width: '100%' }}>
                    <Button
                        size="large"
                        color="primary"
                        aria-label="add a user"
                        startIcon={<PersonAddAlt1TwoToneIcon />}
                        onClick={() => setIsAddUserOpen(true)}
                    >
                        {t('users.adduser', { ns: 'users' })}
                    </Button>
                    <Button
                        size="large"
                        color="primary"
                        aria-label="add multiple users"
                        startIcon={<GroupAddTwoToneIcon />}
                        onClick={() => setIsAddUserFileOpen(true)}
                    >
                        {t('users.addmultipleuser', { ns: 'users' })}
                    </Button>
                </Stack>
                <TextField
                    placeholder={t('users.userquerysearch', {
                        ns: 'users',
                    })}
                    size='small'
                    onChange={(event) => {
                        setUserQuery(event.target.value);
                        setUsers(datac?.users
                            .filter((user) => {
                                return (
                                    userQuery === '' ||
                                    user.name.concat(" ", user.surname)
                                        .toLowerCase()
                                        .includes(event.target.value.toLowerCase())
                                )
                            }))
                    }
                    }
                    sx={{
                        width: '100%',
                        paddingBottom: '2%',
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchTwoToneIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <EditUserDialog setSnackBar={setSnackBar}
                    users={users} ></EditUserDialog>
            </Stack>
            <BottomSnackBar
                open={open}
                setOpen={setOpen}
                type={type}
                message={message}
            ></BottomSnackBar>
        </Box>
    )
}
