import { useMutation } from '@apollo/client'

import { CORSI, ELIMINA_CORSO } from '../utils/api'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

import { useTranslation } from 'react-i18next'

export default function DeleteCourseDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'courses'])

    const [updateServer] = useMutation(ELIMINA_CORSO, {
        refetchQueries: [CORSI],
    })

    function setDeletionOK(course) {
        setSnackBar('success', t('deletecourse.coursedeleteddok', { ns: 'courses', course: course }))
        onClose()
    }

    function setDeletionKO() {
        setSnackBar('error', t('deletecourse.coursedeletedko', { ns: 'courses', course: selectedValue.name }))
        onClose()
    }

    function setDeletionNotAuthorized() {
        setSnackBar('error', t('deletecourse.coursedeletednotauthorized', { ns: 'courses' }))
        onClose()
    }

    const handleDelete = async () => {
        try {
            await updateServer({
                variables: { courseId: selectedValue.courseId },
            })
            setDeletionOK(selectedValue.name)
        } catch (e) {
            console.error(e)
            if (e.message.includes('not authorized')) {
                setDeletionNotAuthorized()
            }
            else { setDeletionKO() }
        }
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
                {t('deletecourse.deletecourse', {
                    ns: 'courses',
                    course: selectedValue.name,
                })}
            </DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>{t('button.cancel')}</Button>
                <Button variant="contained" onClick={handleDelete}>
                    {t('button.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
