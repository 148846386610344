import * as React from 'react';
import {
    GridRowModes,
    DataGrid,
    GridActionsCellItem,
    useGridApiContext
} from '@mui/x-data-grid';

import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { GRUPPI, MODIFICA_GRUPPO, PERMESSI } from '../utils/api'
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import { useTranslation } from 'react-i18next'

export default function EditGroupDialog(props) {
    const { onClose, open, setSnackBar } = props;
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [t] = useTranslation(['common', 'departments']);

    const { data: datag, loading: loading_queryg } = useQuery(GRUPPI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    const { data: datap, loading: loading_queryp, refetch } = useQuery(PERMESSI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    const [updateServer] = useMutation(MODIFICA_GRUPPO, {
        refetchQueries: [GRUPPI, PERMESSI]
    })

    useEffect(() => {
        function initData() {
            let list_permissions_names = []

            list_permissions_names = datap.permissions?.map(
                (permission) => permission.name
            )

            const rows = datag.groups?.map((element, index) => {
                const enabled_permessions = element.permissions.map(
                    (permission) => permission.name
                )

                return list_permissions_names.map((per) => {
                    return enabled_permessions.includes(per)
                })
            })

            const zip = (a, b, obj) => {
                a.forEach((k, i) => {
                    obj[k.name] = { value: b[i], perId: k.id }
                })
                return obj
            }

            const rows_value = rows.map((row) => {
                var obj = {}
                return zip(datap.permissions, row, obj)
            })

            const tableRows = datag.groups?.map((group, index) => {
                let row_index = { id: index }
                let name = { name: group.name }
                let groupid = { groupid: group.id }
                let booleans = rows_value[index]

                return { ...row_index, ...name, ...groupid, ...booleans }
            })
            return tableRows
        }

        if (datap && datag) {
            let tableRows = initData()
            setRows(tableRows)
        }
    }, [datap, datag])

    function setEditOK(group) {
        setSnackBar(
            'success',
            t('departments.groupeditedok', { ns: 'departments', group: group }))
    }

    function setEditKO(group) {
        setSnackBar(
            'error',
            t('departments.groupeditedko', { ns: 'departments', group: group }))
    }

    const handleEdit = async (data) => {
        try {
            const permissions = Object.values(data)
                .filter((value) => value?.value)
                .map((per) => per.perId)
            let res = await updateServer({
                variables: {
                    input: {
                        userGroupId: data.groupid,
                        name: data.name,
                        permissionsIDs: permissions,
                    }
                },
            })
            if (res.data) {
                setEditOK(data.name)
                onClose()
            } else {
                setEditKO(data.name)
                onClose()
                refetch()
            }
        } catch (e) {
            console.error(e)
            setEditKO(data.name)
            onClose()
            refetch()
        }
    }

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        handleEdit(updatedRow)
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    function CustomEditComponent(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleValueChange = (event) => {
            const newValue = event.target.checked;
            const value_copy = Object.assign({}, value);
            value_copy.value = newValue
            apiRef.current.setEditCellValue({ id, field, value: value_copy });
        };

        return <input
            type="checkbox"
            defaultChecked={value.value} onChange={handleValueChange} />;
    }

    const renderRatingEditInputCell = (params) => {
        return <CustomEditComponent {...params} />;
    };

    const tableColumns = datap?.permissions ? datap?.permissions.map((permission, index) => ({
        headerName: permission.name,
        field: permission.name,
        type: 'boolean',
        width: 140,
        editable: true,
        renderCell: (params) => (
            <input
                type="checkbox"
                defaultChecked={params.value.value}
                readOnly
                id={index}
                disabled
            />),
        renderEditCell: renderRatingEditInputCell
    })) : [];

    tableColumns.unshift({ headerName: t('departments.title', { ns: 'departments' }), field: 'name', editable: true, width: 120, })
    tableColumns.unshift({
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 70,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />
            ];
        },
    })

    if (loading_queryg) {
        return <>Loading...</>
    }

    if (loading_queryp) {
        return <>Loading...</>
    }

    return (
        <Dialog onClose={onClose} open={open} maxWidth="lg">
            <DialogContent>
                <Box
                    sx={{
                        height: 500,
                        width: 1000
                    }}>
                    <DataGrid
                        rowsPerPageOptions={[100]}
                        disableColumnSelector
                        disableColumnMenu
                        disableColumnFilter
                        disableDensitySelector
                        disableSelectionOnClick
                        rows={rows}
                        columns={tableColumns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        experimentalFeatures={{ newEditingApi: true }}
                    /></Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('button.ok', { ns: 'common' })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}