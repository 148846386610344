import * as React from 'react'
import { useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import BottomSnackBar from '../components/BottomSnackBar'
import { useQuery } from '@apollo/client'
import { GRUPPI } from '../utils/api'

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'

import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone'
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import DeleteGroupDialog from '../dialogs/DeleteGroupDialog'
import AddGroupDialog from '../dialogs/AddGroupDialog'
import EditGroupDialog from '../dialogs/EditGroupDialog'
import EditCoursesinGroupsDialog from '../dialogs/EditCoursesinGroupsDialog'
import EditUsersinGroupsDialog from '../dialogs/EditUsersinGroupsDialog'

function GroupEntry(props) {
    const [t] = useTranslation(['common', 'departments'])
    const [isDeleteGroupOpen, setIsDeleteGroupOpen] = useState(false)
    const [isEditUsersOpen, setIsEditUsersOpen] = useState(false)
    const [isEditCoursesOpen, setIsEditCoursesOpen] = useState(false)

    return (
        <Card sx={{ minWidth: '30%' }}>
            <DeleteGroupDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsDeleteGroupOpen(false)}
                selectedValue={props.group}
                open={isDeleteGroupOpen}
            />
            <EditUsersinGroupsDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsEditUsersOpen(false)}
                selectedValue={props.group}
                open={isEditUsersOpen}
            />
            <EditCoursesinGroupsDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsEditCoursesOpen(false)}
                selectedValue={props.group}
                open={isEditCoursesOpen}
            />
            <CardContent sx={{ minHeight: '70px' }}>
                <Typography gutterBottom variant="h5">
                    {props.group.name}
                </Typography>
            </CardContent>
            <CardActions
                disableSpacing
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Stack direction="row">
                    <Tooltip
                        title={t('departments.editusers', {
                            ns: 'departments',
                        })}
                    >
                        <IconButton
                            aria-label={t('departments.editusers', {
                                ns: 'departments',
                            })}
                            onClick={() => setIsEditUsersOpen(true)}
                        >
                            <PeopleTwoToneIcon></PeopleTwoToneIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t('departments.editcourses', {
                            ns: 'departments',
                        })}
                    >
                        <IconButton
                            aria-label={t('departments.editcourses', {
                                ns: 'departments',
                            })}
                            onClick={() => setIsEditCoursesOpen(true)}
                        >
                            <MenuBookTwoToneIcon></MenuBookTwoToneIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t('departments.deletegroup', {
                            ns: 'departments',
                        })}
                    >
                        <IconButton
                            aria-label={t('departments.deletegroup', {
                                ns: 'departments',
                            })}
                            onClick={() => setIsDeleteGroupOpen(true)}
                        >
                            <DeleteTwoToneIcon></DeleteTwoToneIcon>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </CardActions>
        </Card>
    )
}

export default function ShowGroups() {
    const [t] = useTranslation(['common', 'departments'])

    const [open, setOpen] = React.useState(false)
    const [type, setType] = React.useState('info')
    const [message, setMessage] = React.useState('')
    const [groupQuery, setGroupQuery] = useState('')

    const [isAddGroupOpen, setIsAddGroupOpen] = useState(false)

    const [isEditGroupOpen, setIsEditGroupOpen] = useState(false)

    const { data, loading: loading_query } = useQuery(GRUPPI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    if (loading_query) {
        return <>Loading...</>
    }

    function setSnackBar(type, message) {
        setOpen(true)
        setType(type)
        setMessage(message)
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                overflowX: 'auto',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Toolbar />
            <AddGroupDialog
                setSnackBar={setSnackBar}
                onClose={() => setIsAddGroupOpen(false)}
                open={isAddGroupOpen}
            />
            <EditGroupDialog
                setSnackBar={setSnackBar}
                onClose={() => setIsEditGroupOpen(false)}
                open={isEditGroupOpen}
            />
            <Stack sx={{ alignItems: 'center' }} spacing={2}>
                <Typography
                    variant="h1"
                    sx={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}
                >
                    {t('departments.title', { ns: 'departments' })}
                </Typography>
                <Stack
                    sx={{ alignItems: 'space-between', width: '100%' }}
                    direction="row"
                    spacing={2}
                >
                    <Button
                        aria-label={t('departments.addnew', {
                            ns: 'departments',
                        })}
                        onClick={() => setIsAddGroupOpen(true)}
                    >
                        <AddTwoToneIcon></AddTwoToneIcon>
                        {t('departments.addnew', { ns: 'departments' })}
                    </Button>
                    <Button
                        aria-label={t('departments.editper', {
                            ns: 'departments',
                        })}
                        onClick={() => setIsEditGroupOpen(true)}
                    >
                        <EditTwoToneIcon></EditTwoToneIcon>
                        {t('departments.editper', { ns: 'departments' })}
                    </Button>
                    <TextField
                        placeholder={t('departments.querysearch', {
                            ns: 'departments',
                        })}
                        onChange={(event) => setGroupQuery(event.target.value)}
                        sx={{
                            width: '50%',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    columns={{ xs: 2, sm: 8, md: 12 }}
                >
                    {data?.groups
                        .filter((group) => {
                            return (
                                groupQuery === '' ||
                                group.name
                                    .toLowerCase()
                                    .includes(groupQuery.toLowerCase())
                            )
                        })
                        .map((group, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                                <GroupEntry
                                    group={group}
                                    setSnackBar={setSnackBar}
                                ></GroupEntry>
                            </Grid>
                        ))}
                </Grid>
            </Stack>
            <BottomSnackBar
                open={open}
                setOpen={setOpen}
                type={type}
                message={message}
            ></BottomSnackBar>
        </Box>
    )
}
