import { useState } from 'react'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddBoxIcon from '@mui/icons-material/AddBox'
import Paper from '@mui/material/Paper'

function TableDemo(props) {
    const [t] = [props.t]

    function emptyValue() {
        let newData = {}
        newData[String(props.firstcolumn)] = ''
        newData[String(props.secondcolumn)] = ''
        return newData
    }

    // Defining a state named rows
    // which we can update by calling on setRows function
    let initial_value =
        props.defaultValue === undefined || props.defaultValue.length === 0
            ? [emptyValue()]
            : props.defaultValue
    const [rows, setRows] = useState(initial_value)

    // Function For adding new empty row object
    const handleAdd = () => {
        setRows([...rows, emptyValue()])
    }

    // The handleInputChange handler can listen for changes
    // to input elements and record their values in state
    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...rows]
        list[index][name] = value
        setRows(list)
        props.setValue(list)
    }

    // Handle the deletion of a row
    // Always leave one row! (just clear last row)
    const handleRemoveClick = (index) => {
        const list = [...rows]
        if (list.length === 1) {
            setRows([emptyValue()])
            props.setValue([emptyValue()])
        } else {
            list.splice(index, 1)
            setRows(list)
            props.setValue(list)
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow sx={{ backgroundColor: 'lightgray' }}>
                        <TableCell align="left" colSpan={2}>
                            {' '}
                            {props.title}
                        </TableCell>
                        <TableCell align="center">
                            <div>
                                <Button size="small" onClick={handleAdd}>
                                    <AddBoxIcon onClick={handleAdd} />
                                    {t('messages.newentry')}
                                </Button>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => {
                        return (
                            <TableRow key={'row' + i}>
                                <TableCell align="left" sx={{ width: '42%' }}>
                                    <TextField
                                        variant="standard"
                                        required={props.required}
                                        error={
                                            row[props.firstcolumn] === '' &&
                                            props.checkTable
                                        }
                                        fullWidth
                                        label={props.titlefirstcolumn}
                                        multiline={true}
                                        value={row[props.firstcolumn]}
                                        name={props.firstcolumn}
                                        onChange={(e) =>
                                            handleInputChange(e, i)
                                        }
                                    />
                                </TableCell>
                                <TableCell align="left" sx={{ width: '42%' }}>
                                    <TextField
                                        variant="standard"
                                        label={props.titlesecondcolumn}
                                        required={props.required}
                                        error={
                                            row[props.secondcolumn] === '' &&
                                            props.checkTable
                                        }
                                        fullWidth
                                        multiline={true}
                                        value={row[props.secondcolumn]}
                                        name={props.secondcolumn}
                                        onChange={(e) =>
                                            handleInputChange(e, i)
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center" sx={{ width: '16%' }}>
                                    <div>
                                        <Button
                                            size="small"
                                            onClick={(e) =>
                                                handleRemoveClick(i)
                                            }
                                        >
                                            <DeleteOutlineIcon />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TableDemo
