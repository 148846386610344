import { useLayoutEffect, useState } from 'react'

export const SMALL_SCREEN_BREAKPOINT = 600
export const MEDIUM_SCREEN_BREAKPOINT = 1250
export const LARGE_SCREEN_BREAKPOINT = 1300

export function useWindowSize() {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
        let resizeEventTimer = null

        function updateSize() {
            if (resizeEventTimer) {
                clearTimeout(resizeEventTimer)
            }
            resizeEventTimer = setTimeout(
                () => setSize([window.innerWidth, window.innerHeight]),
                300
            )
        }

        window.addEventListener('resize', updateSize)
        setSize([window.innerWidth, window.innerHeight])
        return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size
}


export function dynamicSort(property) {
    var sortOrder = 1

    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        if (sortOrder === -1) {
            return b[property].localeCompare(a[property])
        } else {
            return a[property].localeCompare(b[property])
        }
    }
}

export function isStringCorrect(string) {
    var specialChars = /[-!@#$%^&*()_+=[\]{};':"\\|,.<>/?]+/;
    var stringLen = string.length

    return !specialChars.test(string) && stringLen > 2 && stringLen <= 64
}