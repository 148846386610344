import { useTranslation } from 'react-i18next'

import InputAutocomplete from './AutocompleteBasic'
import { useQuery } from '@apollo/client'

import { CORSI } from '../utils/api'

export default function Course(props) {
    const [t] = useTranslation('common', { keyPrefix: 'course' })

    const { data, loading: loading_query } = useQuery(CORSI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    return (
        <InputAutocomplete
            defaultValue={props.defaultValue}
            loading={loading_query}
            optionlabel={(option) => option.name}
            required={true}
            multiple={false}
            setErrorRequired={props.setError}
            list_names={data?.courses ? data?.courses : []}
            freeSolo={false}
            setValue={props.setValue}
            error_required={props.error}
            t={t}
        />
    )
}
