import { useState } from 'react'
import { Box, Stack, Toolbar, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import BottomSnackBar from '../components/BottomSnackBar'
import SendButton from '../components/SendButton'
import Course from '../components/Course'
import Lesson from '../components/Lesson'
import VideoUplaod from '../components/VideoUpload'

export default function AddVideo() {
    const [t] = useTranslation(['common', 'videos'])
    const [course, setCourse] = useState({
        courseId: null,
        name: null,
        lessons: [],
    })
    const [lesson, setLesson] = useState({ name: '' })

    const [error_course, setErrorCourse] = useState(false)
    const [error_lesson, setErrorLesson] = useState(false)
    const [error_video, setErrorVideo] = useState(false)

    const [videoFilePath, setVideoFilePath] = useState('')
    const [loading, setLoading] = useState(false)

    const [videoUpload, setVideoUpload] = useState(false)

    const [open, setOpen] = useState(false)
    const [type, setType] = useState('info')
    const [message, setMessage] = useState('')

    function setSnackBar(type, message) {
        setOpen(true)
        setType(type)
        setMessage(message)
    }

    const handleClick = () => {
        if (
            course?.name &&
            course?.name.length !== 0 &&
            lesson?.name &&
            lesson?.name.length !== 0 &&
            videoFilePath &&
            videoFilePath?.length !== 0
        ) {
            //invio
            setLoading(true)
            setVideoUpload(true)
        } else {
            if (!course?.name || course?.name.length === 0) {
                setErrorCourse(true)
            }
            if (!lesson?.name || lesson?.name.length === 0) {
                setErrorLesson(true)
            }
            if (!videoFilePath || videoFilePath?.length === 0) {
                setErrorVideo(true)
            }
        }
    }

    function setCourseandResetLesson(course) {
        setLesson({ name: '' })
        setCourse(course)
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                overflowX: 'auto',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Toolbar />
            <Stack sx={{ alignItems: 'center' }} spacing={2}>
                <Typography
                    variant="h1"
                    sx={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}
                >
                    {t('addvideo.title', { ns: 'videos' })}
                </Typography>
                <Course
                    setError={setErrorCourse}
                    error={error_course}
                    setValue={setCourseandResetLesson}
                />
                <Lesson
                    value={lesson}
                    setError={setErrorLesson}
                    error={error_lesson}
                    setValue={setLesson}
                    list={course?.lessons ? course.lessons : []}
                />
                {error_video && (
                    <Typography
                        sx={{
                            marginTop: 5,
                            marginBottom: 5,
                            textAlign: 'center',
                            color: 'red',
                        }}
                    >
                        {t('addvideo.missingvideo', { ns: 'videos' })}
                    </Typography>
                )}
                <VideoUplaod
                    videoFilePath={videoFilePath}
                    setVideoFilePath={setVideoFilePath}
                    setSnackBar={setSnackBar}
                    loading={loading}
                    setLoading={setLoading}
                    uuid={lesson?.video?.uuid}
                    setErrorVideo={setErrorVideo}
                    videoUpload={videoUpload}
                    setVideoUpload={setVideoUpload}
                    thumbnailURL={null}
                ></VideoUplaod>
                <SendButton
                    handleSendClick={handleClick}
                    loading={loading}
                ></SendButton>
            </Stack>
            <BottomSnackBar
                open={open}
                setOpen={setOpen}
                type={type}
                message={message}
            ></BottomSnackBar>
        </Box>
    )
}
