import TopBar from './TopBar/TopBar'
import { useAADAccount } from '../utils/auth'
import { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import VersionFooter from '../components/VersionFooter'

import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function PageWrapper(props) {
    const [t] = useTranslation('common')
    const [menuopen, setMenu] = useState(props.sidebar)

    function ChangeMenu() {
        setMenu(!menuopen)
    }

    const account = useAADAccount(0)
    if (props.title) {
        document.title = props.title
    }
    return (
        <>
            <Container
                sx={{ display: 'flex', width: '100%', alignItems: 'center' }}
                maxWidth="md"
            >
                <AppBar
                    position="fixed"
                    elevation={0}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                >
                    <TopBar
                        sidebar={props.sidebar}
                        user={{
                            completeName: account.name || 'error',
                            email: account.name || 'error',
                        }}
                        buttons={[
                            /*{
                            text: 'Home',
                            urn: '/',
                        },*/
                            {
                                text: t('topBar.addCourse'), //'Aggiungi Corso',
                                urn: '/addcourse',
                            },
                            {
                                text: t('topBar.addLesson'),
                                urn: '/addlesson',
                            },
                            {
                                text: t('topBar.addVideo'),
                                urn: '/addvideo',
                            },
                            {
                                text: t('topBar.courses'),
                                urn: '/courses',
                            },
                            {
                                text: t('topBar.users'),
                                urn: '/users',
                            },
                            {
                                text: t('topBar.departments'),
                                urn: '/departments',
                            },
                        ]}
                        setMenu={ChangeMenu}
                    />
                </AppBar>
                {props.children}
            </Container>
            <VersionFooter />
        </>
    )
}
