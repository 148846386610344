import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material'

import { CREA_GRUPPO, GRUPPI } from '../utils/api'

import { useTranslation } from 'react-i18next'
import { isStringCorrect } from '../utils/utils'

export default function AddGroupDialog(props) {
    const [t] = useTranslation(['common', 'departments'])
    const [groupName, setGroupName] = useState('')

    const [errorName, setErrorName] = useState(false)

    const { onClose, open, setSnackBar } = props

    const MINIMUM_LENGTH = 3

    const handleChangeName = (e) => {
        setGroupName(e.target.value)
        setErrorName(false)
    }

    const [updateServer] = useMutation(CREA_GRUPPO, {
        refetchQueries: [GRUPPI],
    })

    function setCreateOK() {
        setSnackBar('success', t('departments.groupcreatedok', { ns: 'departments', group: groupName }))
        onClose()
    }

    function setCreateKO() {
        setSnackBar('error', t('departments.groupcreatedko', { ns: 'departments' }))
        onClose()
    }

    function setEditSameName() {
        setSnackBar('error', t('departments.groupcreatedsamename', { ns: 'departments' }))
        onClose()
    }

    function setEditNotAuthorized() {
        setSnackBar('error', t('departments.groupcreatednotauthorized', { ns: 'departments' }))
        onClose()
    }

    const handleAdd = async () => {
        if (isStringCorrect(groupName)) {
            try {
                await updateServer({
                    variables: {
                        input: {
                            userGroupName: groupName,
                        }
                    }
                })
                setCreateOK()
            } catch (e) {
                console.error(e)
                if (e.message.includes('present'))
                    setEditSameName()
                else {
                    if (e.message.includes('not authorized'))
                        setEditNotAuthorized()
                    else
                        setCreateKO()
                }
            }
        }
        else {
            if (groupName?.length === 0) {
                setErrorName(true)
            }
            if (!isStringCorrect(groupName)) {
                setSnackBar('warning',
                    t('messages.wrongString', {
                        ns: 'common',
                        str: t('departments.groupname', { ns: 'departments' })
                    })
                )
            }
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {t('departments.addnew', { ns: 'departments' })}
            </DialogTitle>
            <DialogContent>
                <TextField
                    onChange={(e) => handleChangeName(e)}
                    required
                    id="name"
                    label={t('departments.groupname', { ns: 'departments' })}
                    fullWidth
                    variant="standard"
                    error={!!errorName}
                    helperText={errorName ? t('messages.errorrequiredminlen', { len: MINIMUM_LENGTH }) : ''}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('button.cancel')}</Button>
                <Button onClick={handleAdd} variant="contained">
                    {t('button.add')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
