import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Avatar,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material'

import { LARGE_SCREEN_BREAKPOINT } from '../../utils/utils'

export function TopBarMenu(props) {
    const handleClick = (event) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    return (
        <div className="user-profile-container">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
                onClick={handleClick}
            >
                {props.width > LARGE_SCREEN_BREAKPOINT && (
                    <Typography sx={{ minWidth: 100, color: 'black' }}>
                        {props.user.completeName}
                    </Typography>
                )}
                <Tooltip title="">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                    >
                        <Avatar sx={{ width: 50, height: 50 }}>
                            {props.user.completeName.split(' ')[0][0] +
                                props.user.completeName.split(' ')[1][0]}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 50,
                            height: 50,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 25,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {props.buttons
                    ? props.buttons.map((button, index) => (
                          <MenuItem
                              sx={{ height: 65, width: 225 }}
                              key={index}
                              onClick={() => navigate(button.urn)}
                          >
                              <ListItemText>{button.text}</ListItemText>
                              <ListItemIcon>
                                  {React.cloneElement(button.icon)}
                              </ListItemIcon>
                          </MenuItem>
                      ))
                    : null}
            </Menu>
        </div>
    )
}

export default TopBarMenu
