import { useEffect, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'

export function useAuthToken() {
    const { instance, accounts, inProgress } = useMsal()
    const account = useAccount(accounts[0] || {})
    const [token, setToken] = useState<string | null>(null)

    useEffect(() => {
        if (account) {
            instance
                .acquireTokenSilent({
                    scopes: [
                        'api://545a705b-7525-4574-962c-70254e74ac2b/API.Access',
                    ],
                    account,
                })
                .then((response) => {
                    if (response) {
                        console.log('Token was acquired silently')
                        setToken(response.accessToken)
                    } else {
                        console.log('Wrong response received.')
                    }
                })
                .catch((reason) => {
                    console.log(
                        'Error while acquiring token silently. Trying redirect.'
                    )
                    console.log(reason)
                    instance
                        .acquireTokenRedirect({
                            scopes: ['User.Read'],
                            account,
                        })
                        .then((response: any) => {
                            if (response) {
                                console.log('Token was acquired via redirect')
                                setToken(response.accessToken)
                            } else {
                                console.log('Wrong response received.')
                            }
                        })
                        .catch((reason) => {
                            console.log(
                                'Error while acquiring token with redirect.'
                            )
                            console.log(reason)
                        })
                })
        }
    }, [account, instance])

    if (accounts.length > 0) {
        return token
    } else if (inProgress === 'login') {
        return null
    } else {
        return null
    }
}

export function useAADAccount(index: number = 0) {
    const { accounts } = useMsal()
    return useAccount(accounts[index] || {})
}
