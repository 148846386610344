import { useEffect, useState } from 'react'

import {
    Box,
    Breadcrumbs,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useQuery } from '@apollo/client'

import { useAuthToken } from '../utils/auth'
import { CORSI, VIDEOS_BASE_URI } from '../utils/api'
import { getCurrentLocale } from '../utils/locale'

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'

import { useNavigate, useSearchParams } from 'react-router-dom'

import BottomSnackBar from '../components/BottomSnackBar'

import DeleteCourseDialog from '../dialogs/DeleteCourseDialog'
import EditCourseDialog from '../dialogs/EditCourseDialog'
import QuizCourseDialog from '../dialogs/QuizCourseDialog'
import GroupCourseDialog from '../dialogs/GroupCourseDialog'
import DeleteLessonDialog from '../dialogs/DeleteLessonDialog'
import EditLessonDialog from '../dialogs/EditLessonDialog'
import EditVideoDialog from '../dialogs/EditVideoDialog'

function CourseEntry(props) {
    const [t] = useTranslation(['common', 'courses'])
    const [, setSearchParams] = useSearchParams({})

    const [isDeleteCourseOpen, setIsDeleteCourseOpen] = useState(false)
    const [isEditCourseOpen, setIsEditCourseOpen] = useState(false)
    const [isQuizOpen, setIsQuizCourseOpen] = useState(false)
    const [isGroupOpen, setIsGroupOpen] = useState(false)

    function showLessons() {
        props.setExpanded(true)
        setSearchParams({ course: props.course.name })
        props.setSelectedCourseId(props.course.courseId)
        props.setLessonQuery('')
    }

    return (
        <Card sx={{ minWidth: '30%' }}>
            <DeleteCourseDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsDeleteCourseOpen(false)}
                selectedValue={props.course}
                open={isDeleteCourseOpen}
            />
            <EditCourseDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsEditCourseOpen(false)}
                selectedValue={props.course}
                open={isEditCourseOpen}
            />
            <QuizCourseDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsQuizCourseOpen(false)}
                selectedValue={props.course}
                open={isQuizOpen}
            />
            <GroupCourseDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsGroupOpen(false)}
                selectedValue={props.course}
                open={isGroupOpen}
            />
            <CardActionArea onClick={showLessons}>
                <Tooltip title={props.course.name}>
                    <CardContent
                        sx={{
                            height: 30,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography variant="h5" noWrap>
                            {props.course.name}
                        </Typography>
                        <ChevronRightTwoToneIcon color="secondary"></ChevronRightTwoToneIcon>
                    </CardContent>
                </Tooltip>
            </CardActionArea>
            <CardContent sx={{ height: 100 }}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        wordWrap: 'break-word',
                        maxWidth: 250,
                        maxHeight: '100%',
                    }}
                    overflow="auto"
                    component="div"
                >
                    {props.course.description}
                </Typography>
            </CardContent>
            <CardActions
                disableSpacing
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Tooltip title={t('courses.quiz', { ns: 'courses' })}>
                    <IconButton
                        aria-label={t('courses.quiz', { ns: 'courses' })}
                        onClick={() => setIsQuizCourseOpen(true)}
                    >
                        <QuizTwoToneIcon></QuizTwoToneIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('courses.editcourse', { ns: 'courses' })}>
                    <IconButton
                        aria-label={t('courses.editcourse', { ns: 'courses' })}
                        onClick={() => setIsEditCourseOpen(true)}
                    >
                        <EditTwoToneIcon></EditTwoToneIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('courses.group', { ns: 'courses' })}>
                    <IconButton
                        aria-label={t('courses.group', { ns: 'courses' })}
                        onClick={() => setIsGroupOpen(true)}
                    >
                        <GroupsTwoToneIcon></GroupsTwoToneIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('courses.deletecourse', { ns: 'courses' })}>
                    <IconButton
                        aria-label={t('courses.deletecourse', {
                            ns: 'courses',
                        })}
                        onClick={() => setIsDeleteCourseOpen(true)}
                    >
                        <DeleteTwoToneIcon></DeleteTwoToneIcon>
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    )
}

function LessonEntry(props) {
    const [t] = useTranslation(['common', 'courses', 'lessons'])
    const [isDeleteLessonOpen, setIsDeleteLessonOpen] = useState(false)
    const [isEditLessonOpen, setIsEditLessonOpen] = useState(false)
    const [isEditVideoOpen, setIsEditVideoOpen] = useState(false)

    return (
        <Card sx={{ minWidth: '50%' }}>
            <DeleteLessonDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsDeleteLessonOpen(false)}
                selectedValue={props.lesson}
                open={isDeleteLessonOpen}
            />
            <EditLessonDialog
                setSnackBar={props.setSnackBar}
                onClose={() => setIsEditLessonOpen(false)}
                selectedValue={props.lesson}
                open={isEditLessonOpen}
            />
            <EditVideoDialog
                token={props.token}
                setSnackBar={props.setSnackBar}
                onClose={() => setIsEditVideoOpen(false)}
                selectedValue={props.lesson.video}
                open={isEditVideoOpen}
            />
            <CardActionArea
                sx={{ height: 300 }}
                onClick={() => setIsEditVideoOpen(true)}
            >
                <Tooltip title={props.lesson.name}>
                    <CardContent
                        sx={{
                            height: 30,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography variant="h5" noWrap>
                            {props.lesson.name}
                        </Typography>
                        <ChevronRightTwoToneIcon color="secondary"></ChevronRightTwoToneIcon>
                    </CardContent>
                </Tooltip>
                {props.lesson.video.thumbnailURL && (
                    <CardMedia
                        id="th"
                        component="img"
                        src={
                            VIDEOS_BASE_URI +
                            props.lesson.video.thumbnailURL +
                            '?token=' +
                            props.token +
                            '&lang=' +
                            getCurrentLocale().code
                        }
                        alt="Video lesson thumbnail"
                        sx={{ alignSelf: 'center', height: 200 }}
                        crossOrigin="anonymous"
                    />
                )}
            </CardActionArea>
            <CardActions
                disableSpacing
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Tooltip title={t('courses.editlesson', { ns: 'courses' })}>
                    <IconButton
                        aria-label={t('courses.editlesson', {
                            ns: 'courses',
                        })}
                        onClick={() => setIsEditLessonOpen(true)}
                    >
                        <EditTwoToneIcon></EditTwoToneIcon>
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={t('courses.deletelesson', { ns: 'courses' })}
                >
                    <IconButton
                        aria-label={t('courses.deletelesson', {
                            ns: 'courses',
                        })}
                        onClick={() => setIsDeleteLessonOpen(true)}
                    >
                        <DeleteTwoToneIcon></DeleteTwoToneIcon>
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    )
}

export default function ShowCourses() {
    const [t] = useTranslation(['common', 'courses'])

    const [open, setOpen] = useState(false)
    const [type, setType] = useState('info')
    const [message, setMessage] = useState('')
    const [courseQuery, setCourseQuery] = useState('')
    const [lessonQuery, setLessonQuery] = useState('')

    const [selectedCourseId, setSelectedCourseId] = useState(null)
    const [expanded, setExpanded] = useState(false)

    const { data } = useQuery(CORSI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    function setSnackBar(type, message) {
        setOpen(true)
        setType(type)
        setMessage(message)
    }

    const navigate = useNavigate()

    useEffect(() => {
        if (selectedCourseId && data && data.courses) {
            const course = data.courses.find(
                (c) => c.courseId === selectedCourseId
            )
            setSelectedCourseId(course.courseId)
        }
    }, [data, selectedCourseId])

    const token = useAuthToken()
    if (token === null) {
        return (
            <div className="unauth-container">
                <CircularProgress color="secondary" />
            </div>
        )
    }

    const selectedCourse = data?.courses.find(
        (c) => c.courseId === selectedCourseId
    )

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                overflowX: 'auto',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Toolbar />
            <Stack sx={{ alignItems: 'center' }} spacing={2}>
                <Typography
                    variant="h1"
                    sx={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}
                >
                    {t('courses.title', { ns: 'courses' })}
                </Typography>
                <Collapse in={!expanded} sx={{ width: '100%' }}>
                    <TextField
                        placeholder={t('courses.coursequerysearch', {
                            ns: 'courses',
                        })}
                        onChange={(event) => setCourseQuery(event.target.value)}
                        sx={{
                            width: '100%',
                            paddingBottom: '2%',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 2, sm: 8, md: 12 }}
                    >
                        {data?.courses
                            .filter((course) => {
                                return (
                                    courseQuery === '' ||
                                    course.name
                                        .toLowerCase()
                                        .includes(courseQuery.toLowerCase())
                                )
                            })
                            .map((course, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <CourseEntry
                                        course={course}
                                        setExpanded={setExpanded}
                                        setSelectedCourseId={
                                            setSelectedCourseId
                                        }
                                        setSnackBar={setSnackBar}
                                        setLessonQuery={setLessonQuery}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </Collapse>
                <Collapse in={expanded} sx={{ width: '100%' }}>
                    <Stack direction="row" spacing={2}>
                        <Tooltip
                            title={t('courses.backcourses', { ns: 'courses' })}
                        >
                            <IconButton
                                onClick={() => {
                                    navigate(-1)
                                    setExpanded(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        </Tooltip>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant="h5" color="text.primary">
                                {selectedCourse?.name}
                            </Typography>
                        </Breadcrumbs>
                    </Stack>
                    <TextField
                        placeholder={t('courses.lessonquerysearch', {
                            ns: 'courses',
                        })}
                        onChange={(event) => setLessonQuery(event.target.value)}
                        sx={{
                            width: '100%',
                            paddingBottom: '2%',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 2, sm: 8, md: 12 }}
                    >
                        {selectedCourse?.lessons
                            .filter((lesson) => {
                                return (
                                    lessonQuery === '' ||
                                    lesson.name
                                        .toLowerCase()
                                        .includes(lessonQuery.toLowerCase())
                                )
                            })
                            .map((lesson, index) => (
                                <Grid item xs={2} sm={6} md={6} key={index}>
                                    <LessonEntry
                                        lesson={lesson}
                                        token={token}
                                        setSnackBar={setSnackBar}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </Collapse>
            </Stack>
            <BottomSnackBar
                open={open}
                setOpen={setOpen}
                type={type}
                message={message}
            ></BottomSnackBar>
        </Box>
    )
}
