import { useState } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from '@mui/material'

import { useMutation, useQuery } from '@apollo/client'
import SendButton from '../components/SendButton'
import {
    AGGIUNGI_UTENTE_A_GRUPPO,
    CREA_GRUPPO,
    CREA_UTENTE,
    GRUPPI,
    UTENTI,
} from '../utils/api'

import * as XLSX from 'xlsx'

import ProgressBar from '../components/LoadingProgressBar'

import { useTranslation } from 'react-i18next'
import excelfile from '../assets/mockupFiles/Elenco Corsi DTE + Digital Pills.xlsx'

export default function AddUserFileDialog(props) {
    const [t] = useTranslation(['common', 'users'])
    const [qa, setQA] = useState(null)
    const [loading, setLoading] = useState(false)
    const [fileReady, setFileReady] = useState(false)

    const [progress, setProgress] = useState(10)

    const [createUser] = useMutation(CREA_UTENTE)

    const [createGroup] = useMutation(CREA_GRUPPO)

    const [addUserToGroup] = useMutation(AGGIUNGI_UTENTE_A_GRUPPO)

    const { data: datau, loading: loading_u } = useQuery(UTENTI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    const { data: datag, loading: loading_g } = useQuery(GRUPPI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    const user_emails = datau?.users.map((u) => u.email)
    const group_names = datag?.groups.map((g) => g.name)

    const handleClick = async () => {
        if (fileReady) {
            setProgress(0)
            setLoading(true)
            let managerGroupId = null

            const toAdd_groups = qa.map((row) => {
                return row['NOME DIPARTIMENTO']
            })

            let unique_toAdd_groups = [...new Set(toAdd_groups)]
            for (let j = 0; j < unique_toAdd_groups.length; j++) {
                if (!group_names.includes(unique_toAdd_groups[j])) {
                    try {
                        let response = await createGroup({
                            variables: {
                                input: {
                                    userGroupName: unique_toAdd_groups[j],
                                },
                            },
                        })
                        if (response.errors) {
                            console.log('Errore')
                            continue
                        }
                        datag?.groups.push(response.data.addUserGroup)
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            if (!group_names.includes('Managers')) {
                try {
                    let response = await createGroup({
                        variables: { input: { userGroupName: 'Managers' } },
                    })
                    if (response.errors) {
                        console.log('Errore')
                        return
                    }
                    managerGroupId = response.data.addUserGroup.id
                    datag?.groups.push(response.data.addUserGroup)
                } catch (e) {
                    console.error(e)
                    return
                }
            } else {
                managerGroupId = datag.groups.filter(
                    (group) => group.name === 'Managers'
                )[0].id
            }

            for (let i = 0; i < qa.length; i++) {
                setProgress((i * 100) / qa.length)
                let row = qa[i]
                let userId = null
                let groupId = null
                groupId = datag.groups.filter(
                    (group) => group.name === row['NOME DIPARTIMENTO']
                )[0].id

                if (!user_emails.includes(row['Email'])) {
                    try {
                        let response = await createUser({
                            variables: {
                                input: {
                                    name: row['Nome'],
                                    surname: row['Cognome'],
                                    email: row['Email'],
                                },
                            },
                        })
                        if (response.errors) {
                            console.log('Errore')
                            continue
                        }
                        userId = response.data.insertUser.id
                        datau.users.push(response.data.insertUser)
                    } catch (e) {
                        console.error(e)
                        if (e.message.includes('already exist')) {
                            userId = datau.users.filter(
                                (user) =>
                                    user.email === row['Email'] ||
                                    (user.name === row['Nome'] &&
                                        user.surname === row['Cognome'])
                            )[0].id
                        } else {
                            console.log('Skipping')
                            continue
                        }
                    }
                } else {
                    userId = datau.users.filter(
                        (user) =>
                            user.email === row['Email'] ||
                            (user.name === row['Nome'] &&
                                user.surname === row['Cognome'])
                    )[0].id
                }

                if (userId !== null && groupId !== null) {
                    try {
                        let response = await addUserToGroup({
                            variables: {
                                input: {
                                    userId: userId,
                                    groupIds: [groupId],
                                },
                            },
                        })
                        if (response.errors) {
                            console.log('Errore')
                            continue
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
                if (row['REFERENTE']?.toLowerCase() === 'si') {
                    try {
                        let response = await addUserToGroup({
                            variables: {
                                input: {
                                    userId: userId,
                                    groupIds: [managerGroupId],
                                },
                            },
                        })
                        if (response.errors) {
                            console.log('Errore')
                            continue
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
            }
            setLoading(false)
            props.setSnackBar('success', t('messages.ok'))

            props.onClose()
        } else {
            props.setSnackBar('error', t('messages.nofile'))
        }
    }

    const onChange = (e) => {
        setFileReady(false)
        const [file] = e.target.files
        const reader = new FileReader()

        reader.onload = (evt) => {
            const bstr = evt.target.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            if (wb.SheetNames.length < 3) return
            const wsname = wb.SheetNames[2]
            const ws = wb.Sheets[wsname]
            const data = XLSX.utils.sheet_to_json(ws, { blankrows: false })

            //Trim
            data.map((row) =>
                Object.keys(row).forEach((k) => (row[k] = row[k].trim()))
            )
            setQA(data)
            setFileReady(true)
            setLoading(false)
        }
        reader.readAsBinaryString(file)
    }

    if (loading_u || loading_g) {
        return <>Loading...</>
    }

    const handleClose = () => {
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>{t('addmultipleusers.usersgroups', { ns: 'users' })}</DialogTitle>
            <DialogContent>
                <Stack sx={{ alignItems: 'center' }} spacing={2}>
                    <Typography
                        variant="caption"
                    >
                        {t('addmultipleusers.uploadexcel', { ns: 'users' })}<a href={excelfile} download="Elenco Corsi DTE + Digital Pills.xlsx">
                            Elenco Corsi DTE + Digital Pills.xlsx
                        </a>
                    </Typography>
                    <input type="file" onChange={onChange} />
                    {loading && <ProgressBar progress={progress} />}
                </Stack>
            </DialogContent>
            <DialogActions>

                <Button onClick={handleClose}>{t('button.cancel')}</Button>
                <SendButton
                    handleSendClick={handleClick}
                    loading={loading}
                ></SendButton>
            </DialogActions>
        </Dialog>
    )
}
