import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { CORSI, GRUPPI, MODIFICA_CORSO } from '../utils/api'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Stack,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

export default function GroupCourseDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'courses'])
    const [updateServer] = useMutation(MODIFICA_CORSO, {
        refetchQueries: [CORSI],
    })

    const [allGroupsSelection, setAllGroupsSelection] = useState([])

    const { data: dataQuery } = useQuery(GRUPPI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (dataQuery?.groups) {
            let selectedCourseGroupsIDs = selectedValue.viewableBy.map((group) => {
                return group.id
            })

            setAllGroupsSelection(dataQuery?.groups.map((group) => {
                group['selected'] = selectedCourseGroupsIDs.includes(group.id)
                return group
            }))
        }
    }, [dataQuery, selectedValue])

    function setEditOK() {
        setSnackBar('success', t('editcourse.courseeditedok', { ns: 'courses', course: selectedValue.name }))
        onClose()
    }

    function setEditKO() {
        setSnackBar('error', t('editcourse.courseeditedko', { ns: 'courses', course: selectedValue.name }))
        onClose()
    }

    function setEditNotAuthorized() {
        setSnackBar('error', t('editcourse.courseeditednotauthorized', { ns: 'courses' }))
        onClose()
    }

    const handleEdit = async () => {
        const selectedGroups = allGroupsSelection.filter((g) => g.selected)
        let selectedGroupsIDs = selectedGroups.map((r) => parseInt(r.id))
        console.log(selectedGroupsIDs)
        //selectedGroupsIDs.length === 0// course not visible
        try {
            await updateServer({
                variables: {
                    id: selectedValue.id,
                    viewableBy: selectedGroupsIDs,
                },
            })
            setEditOK()
        } catch (e) {
            console.error(e)
            if (e.message.includes('not authorized'))
                setEditNotAuthorized()
            else setEditKO()

        }
    }

    const handleChange = (e) => {
        const allg = [...allGroupsSelection]
        const groupIndex = e.target.value
        const isSelected = e.target.checked
        allg[groupIndex].selected = isSelected
        setAllGroupsSelection(allg)
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
                {t('editcourse.editgroupcourse', {
                    ns: 'courses',
                    course: selectedValue.name,
                })}
            </DialogTitle>
            <DialogContent>
                <Stack sx={{ alignItems: 'center' }} spacing={2}>
                    <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 2, sm: 8, md: 12 }}
                    >
                        {allGroupsSelection?.map((group, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleChange}
                                            checked={group.selected}
                                            value={index}
                                        />
                                    }
                                    label={group.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('button.cancel', { ns: 'common' })}
                </Button>
                <Button variant="contained" onClick={handleEdit}>
                    {t('button.modify', { ns: 'common' })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
