import { useEffect, useState } from 'react'

import { Box, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import BottomSnackBar from '../components/BottomSnackBar'
import InputTags from '../components/Tags'
import SendButton from '../components/SendButton'

import { useMutation } from '@apollo/client'

import { CREA_CORSO } from '../utils/api'
import { isStringCorrect } from '../utils/utils'

export default function AddCourse() {
    const [t] = useTranslation(['common', 'courses'])

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [Id, setId] = useState('')

    const [error_name, setErrorName] = useState(false)
    const [error_id, setErrorId] = useState(false)
    const [error_tags, setErrorTags] = useState(false)

    const [loading, setLoading] = useState(false)

    const [open, setOpen] = useState(false)
    const [type, setType] = useState('info')
    const [message, setMessage] = useState('')

    let tags_local = []

    function setTags(tags) {
        tags_local = tags
    }

    const [updateServer, { data, error }] = useMutation(CREA_CORSO)

    useEffect(() => {
        if (data) {
            setLoading(false)
            setOpen(true)
            setType('success')
            setMessage(
                t('addcourse.coursecreatedok', {
                    ns: 'courses',
                    course: data.createCourse.id,
                })
            )
        }
        if (error) {
            setLoading(false)
            setOpen(true)
            setType('error')
            if (error.message.includes('same name'))
                setMessage(
                    t('addcourse.coursecreatedkosamename', { ns: 'courses' })
                )
            else {
                if (error.message.includes('not authorized'))
                    setMessage(
                        t('addcourse.coursecreatedkonotauthorized', { ns: 'courses' })
                    )
                else setMessage(t('addcourse.coursecreatedko', { ns: 'courses' }))
            }
        }
    }, [data, error, t])

    const handleClick = async () => {
        setOpen(false)
        if (isStringCorrect(name) && Id.length !== 0 && tags_local.length !== 0) {
            setLoading(true)
            try {
                await updateServer({
                    variables: {
                        name: name,
                        description: description,
                        courseId: Id,
                        tags: tags_local,
                    },
                })
            } catch (e) {
                console.error(e)
            }
        } else {
            if (name.length === 0) {
                setErrorName(true)
            }
            if (!isStringCorrect(name)) {
                setOpen(true)
                setType('warning')
                setMessage(
                    t('messages.wrongString', {
                        ns: 'common',
                        str:  t('addcourse.name', {ns: 'courses'})
                    })
                )
            }
            if (Id.length === 0) {
                setErrorId(true)
            }
            if (tags_local.length === 0) {
                setErrorTags(true)
            }
        }
    }

    const handleChangeName = (e) => {
        setErrorName(false)
        setName(e.target.value)
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    const handleChangeId = (e) => {
        setErrorId(false)
        setId(e.target.value)
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                overflowX: 'auto',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Toolbar />
            <Stack sx={{ alignItems: 'center' }} spacing={2}>
                <Typography
                    variant="h1"
                    sx={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}
                >
                    {t('addcourse.title', { ns: 'courses' })}
                </Typography>
                <TextField
                    fullWidth
                    label={t('addcourse.name', { ns: 'courses' })}
                    variant="outlined"
                    required
                    value={name}
                    error={!!error_name}
                    onChange={(e) => handleChangeName(e)}
                    helperText={error_name ? t('messages.errorrequired') : ''}
                />
                <TextField
                    multiline
                    fullWidth
                    label={t('addcourse.description', { ns: 'courses' })}
                    variant="outlined"
                    value={description}
                    onChange={(e) => handleChangeDescription(e)}
                />
                <TextField
                    fullWidth
                    label={t('addcourse.id', { ns: 'courses' })}
                    variant="outlined"
                    required
                    value={Id}
                    error={!!error_id}
                    onChange={(e) => handleChangeId(e)}
                    helperText={error_id ? t('messages.errorrequired') : ''}
                />
                <InputTags
                    error_tags={error_tags}
                    setErrorTags={setErrorTags}
                    setTags={setTags}
                    tags={tags_local}
                />
                <SendButton handleSendClick={handleClick} loading={loading} />
            </Stack>
            <BottomSnackBar
                open={open}
                setOpen={setOpen}
                type={type}
                message={message}
            />
        </Box>
    )
}
