import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'

import { CORSI, MODIFICA_CORSO } from '../utils/api'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from '@mui/material'

import InputTags from '../components/Tags'

import { useTranslation } from 'react-i18next'
import { isStringCorrect } from '../utils/utils'

export default function EditCourseDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'courses'])
    const [updateServer] = useMutation(MODIFICA_CORSO, {
        refetchQueries: [CORSI],
    })
    const [name, setName] = useState(selectedValue.name)
    const [description, setDescription] = useState(selectedValue.description)

    const [errorName, setErrorName] = useState(false)
    const [errorTags, setErrorTags] = useState(false)

    const handleChangeName = (e) => {
        setErrorName(false)
        setName(e.target.value)
    }
    useEffect(() => {
        setName(selectedValue.name)
        setDescription(selectedValue.description)
    }, [selectedValue])

    const handleChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    let tags_local = []

    function setTags(tags) {
        tags_local = tags
    }

    function setEditOK() {
        setSnackBar('success', t('editcourse.courseeditedok', { ns: 'courses', course: name }))
        onClose()
    }

    function setEditKO() {
        setSnackBar('error', t('editcourse.courseeditedko', { ns: 'courses', course: name }))
        onClose()
    }

    function setEditNotAuthorized() {
        setSnackBar('error', t('editcourse.courseeditednotauthorized', { ns: 'courses' }))
        onClose()
    }

    function setEditSameName() {
        setSnackBar('error', t('editcourse.courseeditedsamename', { ns: 'courses' }))
        onClose()
    }

    const handleEdit = async () => {
        if (isStringCorrect(name) && tags_local.length !== 0) {
            try {
                await updateServer({
                    variables: {
                        id: selectedValue.id,
                        name: name,
                        description: description,
                        tags: tags_local,
                    },
                })
                setEditOK(name)
            } catch (e) {
                console.error(e)
                if (e.message.includes('same name'))
                    setEditSameName()
                else {
                    if (e.message.includes('not authorized'))
                        setEditNotAuthorized()
                    else setEditKO()
                }
            }
        } else {
            if (name.length === 0) {
                setErrorName(true)
            }
            if (!isStringCorrect(name)) {
                setSnackBar('warning',
                    t('messages.wrongString', {
                        ns: 'common',
                        str: t('addcourse.name', { ns: 'courses' })
                    })
                )
            }
            if (tags_local.length === 0) {
                setErrorTags(true)
            }
        }
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
                {t('editcourse.editcourse', {
                    ns: 'courses',
                    course: selectedValue.name,
                })}
            </DialogTitle>
            <DialogContent>
                <Stack sx={{ alignItems: 'center' }} spacing={2}>
                    <TextField sx={{ display: 'none' }} />
                    <TextField
                        fullWidth
                        label={t('addcourse.name', { ns: 'courses' })}
                        variant="outlined"
                        required
                        value={name}
                        error={!!errorName}
                        onChange={(e) => handleChangeName(e)}
                        helperText={
                            errorName ? t('messages.errorrequired') : ''
                        }
                    />
                    <TextField
                        fullWidth
                        label={t('addcourse.description', { ns: 'courses' })}
                        variant="outlined"
                        value={description}
                        onChange={(e) => handleChangeDescription(e)}
                    />
                    <InputTags
                        error_tags={errorTags}
                        setErrorTags={setErrorTags}
                        setTags={setTags}
                        tags={tags_local}
                        defaultValue={selectedValue.tags.map((t) => t.name)}
                    ></InputTags>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('button.cancel', { ns: 'common' })}
                </Button>
                <Button variant="contained" onClick={handleEdit}>
                    {t('button.modify', { ns: 'common' })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
