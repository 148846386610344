import { useState } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'

import VideoUplaod from '../components/VideoUpload'

import { getCurrentLocale } from '../utils/locale'
import { VIDEOS_BASE_URI } from '../utils/api'

import { useTranslation } from 'react-i18next'

export default function EditVideoDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'videos'])

    let uuid = props.selectedValue.uuid
    let name = props.selectedValue.name

    const [error_video, setErrorVideo] = useState(false)
    const [videoFilePath, setVideoFilePath] = useState(
        VIDEOS_BASE_URI +
            uuid +
            '/mp4?token=' +
            props.token +
            '&lang=' +
            getCurrentLocale().code
    )

    const [loading, setLoading] = useState(false)
    const [videoUpload, setVideoUpload] = useState(false)

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
                {t('editvideo.editvideo', {
                    ns: 'videos',
                    video: selectedValue.name,
                })}
            </DialogTitle>
            <DialogContent>
                {error_video && (
                    <Typography
                        sx={{
                            marginTop: 5,
                            marginBottom: 5,
                            textAlign: 'center',
                            color: 'red',
                        }}
                    >
                        {t('addvideo.missingvideo', { ns: 'videos' })}
                    </Typography>
                )}
                <VideoUplaod
                    videoFilePath={videoFilePath}
                    setVideoFilePath={setVideoFilePath}
                    setSnackBar={setSnackBar}
                    loading={loading}
                    setLoading={setLoading}
                    uuid={uuid}
                    setErrorVideo={setErrorVideo}
                    videoUpload={videoUpload}
                    setVideoUpload={setVideoUpload}
                    thumbnailURL={
                        props.selectedValue.thumbnailURL
                            ? VIDEOS_BASE_URI +
                              props.selectedValue.thumbnailURL +
                              '?token=' +
                              props.token +
                              '&lang=' +
                              getCurrentLocale().code
                            : null
                    }
                    name={name}
                ></VideoUplaod>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('button.cancel', { ns: 'common' })}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (videoFilePath && videoFilePath?.length !== 0) {
                            setLoading(true)
                            setVideoUpload(true)
                        } else {
                            setErrorVideo(true)
                        }
                    }}
                >
                    {t('button.modify', { ns: 'common' })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
