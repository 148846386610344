import { useMutation } from '@apollo/client'

import { CORSI, ELIMINA_LEZIONE } from '../utils/api'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

import { useTranslation } from 'react-i18next'

export default function DeleteLessonDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'lessons'])

    const [updateServer] = useMutation(ELIMINA_LEZIONE, {
        refetchQueries: [CORSI],
    })

    function setDeletionOK(lesson) {
        setSnackBar('success', t('deletelesson.lessondeleteddok', { ns: 'lessons', lesson: lesson, }))
        onClose()
    }

    function setDeletionKO(lesson) {
        setSnackBar('error', t('deletelesson.lessondeletedko', { ns: 'lessons', lesson: lesson }))
        onClose()
    }

    function setDeletionNotAuthorized() {
        setSnackBar('error', t('deletelesson.lessondeletednotauthorized', { ns: 'lessons' }))
        onClose()
    }

    const handleDelete = async () => {
        try {
            await updateServer({
                variables: { lessonId: selectedValue.lessonId },
            })
            setDeletionOK(selectedValue.name)
        } catch (e) {
            console.error(e)
            if (e.message.includes('not authorized')) {
                setDeletionNotAuthorized()
            }
            else { setDeletionKO(selectedValue.name) }
        }
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
                {t('deletelesson.deletelesson', {
                    ns: 'lessons',
                    lesson: selectedValue.name,
                })}
            </DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>{t('button.cancel')}</Button>
                <Button variant="contained" onClick={handleDelete}>
                    {t('button.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
