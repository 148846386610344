import React from 'react'
import { useMsal } from '@azure/msal-react'
import { useAADAccount } from '../utils/auth'
import { Navigate } from 'react-router-dom'

export default function Logout() {
    const { instance } = useMsal()
    const account = useAADAccount()

    if (account) {
        instance.logoutRedirect()
        return <p>Logging out...</p>
    } else {
        return <Navigate to="/" />
    }
}
