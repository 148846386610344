import { useMutation } from '@apollo/client'

import { ELIMINA_GRUPPO, GRUPPI } from '../utils/api'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

import { useTranslation } from 'react-i18next'

export default function DeleteGroupDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'departments'])

    const [updateServer] = useMutation(ELIMINA_GRUPPO, {
        refetchQueries: [GRUPPI],
    })

    function setDeletionOK(group) {
        setSnackBar('success', t('departments.groupdeleteddok', { ns: 'departments', group: group, }))
    }

    function setDeletionKO(group) {
        setSnackBar('error', t('departments.groupdeletedko', { ns: 'departments', group: group }))
        onClose()
    }

    function setDeletionNotAuthorized() {
        setSnackBar('error', t('departments.groupdeletednotauthorized', { ns: 'departments'}))
        onClose()
    }

    const handleDelete = async () => {
        try {
            await updateServer({
                variables: { input: { userGroupId: selectedValue.id } },
            })
            setDeletionOK(selectedValue.name)
        } catch (e) {
            console.error(e)
            if (e.message.includes('not authorized')) {
                setDeletionNotAuthorized()
            }
            else
            setDeletionKO(selectedValue.name)
        }
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
                {t('departments.deletegroup', { ns: 'departments' }) +
                    ' ' +
                    selectedValue.name}
            </DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>{t('button.cancel')}</Button>
                <Button variant="contained" onClick={handleDelete}>
                    {t('button.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
