import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'

import { useTranslation } from 'react-i18next'

export default function SendButton(props) {
    const [t] = useTranslation(['common'])
    return (
        <LoadingButton
            sx={{ alignSelf: 'flex-end' }}
            color="secondary"
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={props.handleSendClick}
            loading={props.loading}
            loadingPosition="end"
        >
            {t('button.send')}
        </LoadingButton>
    )
}
