import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import {
    createTheme,
    responsiveFontSizes,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material'

import { getStoredLocale } from './utils/localstorage'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_en_us from './translations/en-US/common.json'
import lessons_en_us from './translations/en-US/lessons.json'
import videos_en_us from './translations/en-US/videos.json'
import courses_en_us from './translations/en-US/courses.json'
import departments_en_us from './translations/en-US/departments.json'
import users_en_us from './translations/en-US/users.json'

import common_it_IT from './translations/it-IT/common.json'
import lessons_it_IT from './translations/it-IT/lessons.json'
import videos_it_IT from './translations/it-IT/videos.json'
import courses_it_IT from './translations/it-IT/courses.json'
import departments_it_IT from './translations/it-IT/departments.json'
import users_it_IT from './translations/it-IT/users.json'

const configuration = {
    auth: {
        clientId: '545a705b-7525-4574-962c-70254e74ac2b',
        authority:
            'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd',
        redirectUri: '/',
        postLogoutRedirectUri: 'https://www.abb.com',
    },
}

i18next.init({
    interpolation: { escapeValue: false },
    lng: getStoredLocale()?.code,
    fallbackLng: 'en-US',
    resources: {
        'en-US': {
            common: common_en_us,
            lessons: lessons_en_us,
            videos: videos_en_us,
            courses: courses_en_us,
            departments: departments_en_us,
            users: users_en_us
        },
        'it-IT': {
            common: common_it_IT,
            lessons: lessons_it_IT,
            videos: videos_it_IT,
            courses: courses_it_IT,
            departments: departments_it_IT,
            users: users_it_IT
        },
    },
})

const pca = new PublicClientApplication(configuration)

const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: '#6e6e6e',
            },
            secondary: {
                main: '#ff000f',
            },
        },
        typography: {
            fontFamily: ['ABBVoice', 'sans-serif'].join(','),
            h1: {
                fontWeight: 500,
                fontSize: '4em',
            },
        },
    })
)

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={pca}>
            <I18nextProvider i18n={i18next}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </StyledEngineProvider>
            </I18nextProvider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
