import './App.scss'

import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

import { CircularProgress } from '@mui/material'

import Home from './pages/HomePage'
import Logout from './pages/LogoutPage'
import AddCourse from './pages/AddCoursePage'
import AddVideo from './pages/AddVideoPage'
import ShowCourses from './pages/CoursesPage'
import AddLesson from './pages/AddLessonPage'
import ShowGroups from './pages/GroupsPage'
import Users from './pages/UsersPage'

import PageWrapper from './components/PageWrapper'
import ApolloWrapper from './components/ApolloWrapper'

function App() {
    useMsalAuthentication(InteractionType.Redirect)
    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                <BrowserRouter>
                    <ApolloWrapper>
                        <PageWrapper>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route
                                    exact
                                    path="/addcourse"
                                    element={<AddCourse />}
                                />
                                <Route
                                    path="/addlesson"
                                    element={<AddLesson />}
                                />
                                <Route
                                    path="/addvideo"
                                    element={<AddVideo />}
                                />
                                <Route
                                    path="/courses"
                                    element={<ShowCourses />}
                                />
                                <Route
                                    path="/users"
                                    element={<Users />}
                                />
                                <Route
                                    path="/departments"
                                    element={<ShowGroups />}
                                />
                                <Route
                                    exact
                                    path="/logout"
                                    element={<Logout />}
                                />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </PageWrapper>
                    </ApolloWrapper>
                </BrowserRouter>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="unauth-container">
                    <CircularProgress color="secondary" />
                </div>
            </UnauthenticatedTemplate>
        </React.Fragment>
    )
}

export default App
