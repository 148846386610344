import {
    GridRowModes,
    DataGrid,
    GridActionsCellItem
} from '@mui/x-data-grid';

import { useState } from 'react'
/* TODO non esiste la query di edit user
import { useMutation } from '@apollo/client'

import { UTENTI, MODIFICA_UTENTE } from '../utils/api'
*/
import { Box } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next'

import DeleteUserDialog from '../dialogs/DeleteUserDialog'

export default function EditUserDialog(props) {
    const { setSnackBar, users } = props;

    const [rowModesModel, setRowModesModel] = useState({});
    const [t] = useTranslation(['common', 'users']);

    const [userSelected, setUserSelected] = useState(null)
    const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false)

    const handleDeleteClick = (user) => () => {
        setUserSelected(user);
        setIsDeleteUserOpen(true);
    }

    /* TODO non esiste la query di edit user

    function setEditOK(user) {
        setSnackBar(
            'success',
            t('usereditedok', { ns: 'users', user: user.name.concat(" ", user.surname)  }))
    }

    function setEditKO(user) {
        setSnackBar(
            'error',
            t('usereditedko', { ns: 'users', user: user.name.concat(" ", user.surname) }))
    }

        const handleEdit = async (data) => {
            try {
                let res = await updateServer({
                    variables: {
                        input: {
                            name: data.name,
                            surname: data.surname,
                            email: data.email,
                        }
                    },
                })
                if (res.data) {
                    setEditOK(data)
                } else {
                    setEditKO(data)
                    //should restore old row value
                }
            } catch (e) {
                console.error(e)
                setEditKO(data)
                //should restore old row value
            }
        }*/

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        //handleEdit(updatedRow) //TODO non esiste ancora la query grapql
        return updatedRow;
    };

    const tableColumns = [{ headerName: t('users.name', { ns: 'users' }), field: 'name', editable: true, flex: 0.5 },
    { headerName: t('users.surname', { ns: 'users' }), field: 'surname', editable: true, flex: 0.5, },
    { headerName: t('users.email', { ns: 'users' }), field: 'email', editable: true, flex: 1 },
    {
        field: 'actions',
        type: 'actions',
        headerName: '',
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                    disabled
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    className="textPrimary"
                    onClick={handleDeleteClick(row)}
                    color="inherit"
                />
            ];
        },
    }]

    return (
        <Box
            sx={{
                height: 500,
                width: '100%'
            }}>
            <DeleteUserDialog
                setSnackBar={setSnackBar}
                onClose={() => setIsDeleteUserOpen(false)}
                selectedValue={userSelected}
                open={isDeleteUserOpen}
            />
            <DataGrid
                rowsPerPageOptions={[100]}
                disableColumnSelector
                disableColumnMenu
                disableColumnFilter
                disableDensitySelector
                disableSelectionOnClick
                rows={users}
                columns={tableColumns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
            /></Box>
    )
}