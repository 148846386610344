import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { CORSI, GRUPPI, MODIFICA_CORSO } from '../utils/api'
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputAdornment,
    List,
    ListItem,
    TextField,
    Toolbar,
    Stack,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { dynamicSort } from '../utils/utils'

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'

export default function EditCoursesinGroupsDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'departments'])
    const [updateServer] = useMutation(MODIFICA_CORSO, {
        refetchQueries: [GRUPPI],
    })

    const [courses, setCourses] = useState([])

    const [courseQuery, setCourseQuery] = useState('')

    const { data: datac, loading: loading_queryc } = useQuery(CORSI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (datac?.courses) {
            setCourses(datac.courses.sort(dynamicSort('name')).map((course) => {
                const viewablebygroups = course.viewableBy.map((group) => {
                    return group.id
                })
                course.OldViewablebygroup = viewablebygroups.includes(selectedValue.id)
                course.NewViewablebygroup = viewablebygroups.includes(selectedValue.id)
                return course
            }))
        }
    }, [datac, selectedValue.id])

    function setEditOK(name) {
        setSnackBar('success', t('departments.groupeditedok', { ns: 'departments', group: name }))
    }

    function setEditKO(name) { setSnackBar('error', t('departments.groupeditedko', { ns: 'departments', group: name })) }

    const handleEdit = async () => {
        datac.courses.forEach(async (course, index) => {
            //old groups
            let viewablebygroups = course.viewableBy.map((group) => {
                return parseInt(group.id)
            })

            if (course.NewViewablebygroup === course.OldViewablebygroup) { //non ci sono cambiamenti
                return;
            }
            else {
                //group should be added
                if (course.NewViewablebygroup) {
                    viewablebygroups.push(parseInt(selectedValue.id))
                    try {
                        let res = await updateServer({
                            variables: {
                                id: course.id,
                                viewableBy: viewablebygroups,
                            },
                        })
                        if (res.data) {
                            setEditOK(selectedValue.name)

                        } else {
                            setEditKO(selectedValue.name)

                        }
                    } catch (e) {
                        console.error(e)
                        setEditKO(selectedValue.name)
                    }
                }
                //group should be removed
                else {
                    if (viewablebygroups.includes(parseInt(selectedValue.id))) {
                        let index = viewablebygroups.indexOf(
                            parseInt(selectedValue.id)
                        )
                        viewablebygroups.splice(index, 1)
                        try {
                            let res = await updateServer({
                                variables: {
                                    id: course.id,
                                    viewableBy: viewablebygroups,
                                },
                            })
                            if (res.data) {
                                setEditOK(selectedValue.name)
                            } else {
                                setEditKO(selectedValue.name)
                            }
                        } catch (e) {
                            console.error(e)
                            setEditKO(selectedValue.name)
                        }
                    }
                }
            }
        })
    }

    function updateRows(event, course) {
        course.NewViewablebygroup = event.target.checked
    }

    if (loading_queryc) {
        return <>Loading...</>
    }

    return (
        <Dialog onClose={() => {
            setCourseQuery('');
            onClose()
        }} open={open}>
            <DialogTitle>
                {t('departments.editcourses', { ns: 'departments' }) +
                    ' ' +
                    selectedValue.name}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                <AppBar position='sticky' sx={{backgroundColor: "white", boxShadow: "none"}} >
                        <Toolbar>
                    <TextField placeholder={t('courses.coursequerysearch', { ns: 'courses', })}
                        size='small'
                        onChange={(event) => {
                            setCourseQuery(event.target.value);
                        }}
                        sx={{ width: '100%', paddingBottom: '2%'}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    </Toolbar>
                    </AppBar>
                    <List sx={{ width: '100%' }}>
                        {courses.map((course, index) => (
                            (courseQuery === '' ||
                                course.name
                                    .toLowerCase()
                                    .includes(courseQuery.toLowerCase())) &&
                            <ListItem sx={{ display: 'block' }} key={course.id + index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={course.OldViewablebygroup}
                                            onChange={(e) => updateRows(e, course)}
                                        />
                                    }
                                    label={course.name}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setCourseQuery('');
                    onClose()
                }}>
                    {t('button.cancel', { ns: 'common' })}
                </Button>
                <Button variant="contained" onClick={handleEdit}>
                    {t('button.modify', { ns: 'common' })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
