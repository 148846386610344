import { useTranslation } from 'react-i18next'

import { NativeSelect } from '@mui/material'

import { storeLocale } from '../utils/localstorage'
import { getCurrentLocale } from '../utils/locale'

export function LanguageSwitcher(props) {
    const { i18n } = useTranslation()

    const currentLocale = getCurrentLocale()

    return (
        <NativeSelect
            className="language-switcher-select"
            defaultValue={currentLocale.code}
            onChange={(e) => {
                if (e) {
                    i18n.changeLanguage(e.target.value)
                    storeLocale(
                        props.locales.filter(
                            (locale) => locale.code === e.target.value
                        )[0]
                    )
                    window.location.reload()
                }
            }}
        >
            {props.locales.map((locale, index) => (
                <option key={index} value={locale.code} label={locale.name}>
                    {locale.name}
                </option>
            ))}
        </NativeSelect>
    )
}

export default LanguageSwitcher
