import { useState } from 'react'

import { useMutation } from '@apollo/client'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material'

import {
    CREA_UTENTE,
    UTENTI,
} from '../utils/api'

import { useTranslation } from 'react-i18next'

export default function AddUserDialog(props) {
    const [t] = useTranslation(['common', 'users'])
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')

    const [errorName, setErrorName] = useState(false)
    const [errorSurname, setErrorSurname] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)

    const [addUser] = useMutation(CREA_UTENTE, {
        refetchQueries: [UTENTI],
    })

    const handleChangeName = (e) => {
        setName(e.target.value)
        setErrorName(false)
    }

    const handleChangeSurname = (e) => {
        setSurname(e.target.value)
        setErrorSurname(false)
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleClose = () => {
        setErrorName(false)
        setErrorSurname(false)
        props.onClose()
    }

    const handleCloseandSend = async () => {
        if (name.length !== 0 && surname.length !== 0 && email.length !== 0) {
            try {
                await addUser({
                    variables: { input: { name: name, surname: surname, email: email } },
                })
                props.setSnackBar('success', t('adduser.useraddeddok', { ns: 'users', user: name.concat(" ", surname) }))
                props.onClose()
            } catch (e) {
                console.error(e)
                if (e.message.includes('already'))
                    props.setSnackBar('error', t('adduser.useraddeddkosameemail', { ns: 'users' }))
                else {
                    if (e.message.includes('not authorized'))
                        props.setSnackBar('error', t('adduser.useraddeddkonotauthorized', { ns: 'users' }))
                    else
                        props.setSnackBar('error', t('adduser.useraddeddko', { ns: 'users', user: name.concat(" ", surname) }))
                }
                props.onClose()
            }
        } else {
            if (surname.length === 0) setErrorSurname(true)
            if (name.length === 0) setErrorName(true)
            if (email.length === 0) setErrorEmail(true)
        }
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>{t('adduser.adduser', { ns: 'users' })}</DialogTitle>
            <DialogContent>
                <TextField
                    onChange={(e) => handleChangeName(e)}
                    required
                    id="name"
                    label={t('users.name', { ns: 'users' })}
                    fullWidth
                    variant="standard"
                    error={!!errorName}
                    helperText={errorName ? t('messages.errorrequired') : ''}
                />
                <TextField
                    onChange={(e) => handleChangeSurname(e)}
                    required
                    id="surname"
                    label={t('users.surname', { ns: 'users' })}
                    fullWidth
                    variant="standard"
                    error={!!errorSurname}
                    helperText={
                        errorSurname ? t('messages.errorrequired') : ''
                    }
                />
                <TextField
                    onChange={(e) => handleChangeEmail(e)}
                    required
                    id="email"
                    label={t('users.email', { ns: 'users' })}
                    fullWidth
                    variant="standard"
                    error={!!errorEmail}
                    helperText={
                        errorEmail ? t('messages.errorrequired') : ''
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('button.cancel')}</Button>
                <Button onClick={handleCloseandSend} variant="contained">
                    {t('button.add')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
