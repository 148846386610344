import { useEffect, useState } from 'react'

import { Box, Stack, TextField, Toolbar, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import BottomSnackBar from '../components/BottomSnackBar'
import Levels from '../components/Level'
import InputTags from '../components/Tags'
import InputTeachers from '../components/Teachers'
import SendButton from '../components/SendButton'
import TableDemo from '../components/DynamicTable'

import { useMutation } from '@apollo/client'

import { CREA_LEZIONE } from '../utils/api'

import Languages from '../components/Language'
import Course from '../components/Course'
import { isStringCorrect } from '../utils/utils'

export default function AddLesson() {
    const [t] = useTranslation(['common', 'lessons'])

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [Id, setId] = useState('')
    const [level, setLevel] = useState('')
    const [course, setCourse] = useState('')
    const [language, setLang] = useState('')
    const [requirements, setReq] = useState([{ text: '', description: '' }])
    const [links, setLinks] = useState([{ name: '', url: '' }])
    const [teachers, setTeachers] = useState('')

    const [error_name, setErrorName] = useState(false)
    const [error_id, setErrorId] = useState(false)
    const [error_tags, setErrorTags] = useState(false)
    const [error_course, setErrorCourse] = useState(false)
    const [error_lang, setErrorLang] = useState(false)
    const [error_level, setErrorLevel] = useState(false)
    const [error_teachers, setErrorTeachers] = useState(false)

    //const [checkTableReq, setCheckTableReq] = useState(false); //not required as links and requirements can be empty
    //const [checkTableUrl, setCheckTableUrl] = useState(false); //not required as links and requirements can be empty

    const [loading, setLoading] = useState(false)

    const [open, setOpen] = useState(false)
    const [type, setType] = useState('info')
    const [message, setMessage] = useState('')

    let tags_local = []

    function setTags(tags) {
        tags_local = tags
    }

    const [updateServer, { data, error }] = useMutation(CREA_LEZIONE)

    useEffect(() => {
        if (data) {
            setLoading(false)
            setOpen(true)
            setType('success')
            setMessage(
                t('addlesson.lessoncreatedok', {
                    ns: 'lessons',
                    lesson: data.addLessonToCourse.id,
                })
            )
        }
        if (error) {
            setLoading(false)
            setOpen(true)
            setType('error')
            if (error.message.includes('same name'))
                setMessage(
                    t('addlesson.lessoncreatedkosamename', { ns: 'lessons' })
                )
            else {
                if (error.message.includes('not authorized'))
                    setMessage(
                        t('addlesson.lessoncreatedkonotauthorized', { ns: 'lessons' })
                    )
                else setMessage(t('addlesson.lessoncreatedko', { ns: 'lessons' }))
            }
        }
    }, [data, error, t])

    const handleClick = async () => {
        //setCheckTableReq(true) //not required as links and requirements can be empty
        //setCheckTableUrl(true) //not required as links and requirements can be empty
        if (
            isStringCorrect(name) &&
            Id?.length !== 0 &&
            tags_local?.length !== 0 &&
            course &&
            course?.length !== 0 &&
            language &&
            language?.length !== 0 &&
            level &&
            level?.length !== 0 &&
            teachers?.length !== 0
        ) {
            //invio
            setLoading(true)
            const teac = teachers?.map((t) => {
                const { __typename, id, ...newObj } = t //remove useless key/values pairs, we only need name and surname
                return newObj
            })

            try {
                await updateServer({
                    variables: {
                        name: name,
                        description: description,
                        courseId: course.courseId,
                        tags: tags_local,
                        lessonId: Id,
                        level: String(level),
                        language: language,
                        teachers: teac,
                        requirements: requirements.filter((req) => {
                            return req.text !== '' || req.description !== ''
                        }), //remove entries if both fields are empty
                        usefulLinks: links.filter((link) => {
                            return link.name !== '' || link.url !== ''
                        }), //remove entries if both fields are empty
                    },
                })
            } catch (e) {
                console.error(e)
            }
        } else {
            if (name?.length === 0) {
                setErrorName(true)
            }
            if (!isStringCorrect(name)) {
                setOpen(true)
                setType('warning')
                setMessage(
                    t('messages.wrongString', {
                        ns: 'common',
                        str: t('addlesson.name', { ns: 'lessons' })
                    })
                )
            }
            if (Id?.length === 0) {
                setErrorId(true)
            }
            if (tags_local?.length === 0) {
                setErrorTags(true)
            }
            if (!course || course?.length === 0) {
                setErrorCourse(true)
            }
            if (!language || language?.length === 0) {
                setErrorLang(true)
            }
            if (!level || level?.length === 0) {
                setErrorLevel(true)
            }
            if (!teachers || teachers?.length === 0) {
                setErrorTeachers(true)
            }
        }
    }

    const handleChangeName = (e) => {
        setErrorName(false)
        setName(e.target.value)
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    const handleChangeId = (e) => {
        setErrorId(false)
        setId(e.target.value)
    }

    function setSnackBar(type, message) {
        setOpen(true)
        setType(type)
        setMessage(message)
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                overflowX: 'auto',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Toolbar />
            <Stack sx={{ alignItems: 'center' }} spacing={2}>
                <Typography
                    variant="h1"
                    sx={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}
                >
                    {t('addlesson.title', { ns: 'lessons' })}
                </Typography>
                <Course
                    setError={setErrorCourse}
                    error={error_course}
                    setValue={setCourse}
                    title={t('addlesson.course', { ns: 'lessons' })}
                />
                <TextField
                    fullWidth
                    label={t('addlesson.name', { ns: 'lessons' })}
                    variant="outlined"
                    required
                    value={name}
                    error={!!error_name}
                    onChange={(e) => handleChangeName(e)}
                    helperText={
                        error_name
                            ? t('messages.errorrequired', { ns: 'common' })
                            : ''
                    }
                />
                <TextField
                    multiline
                    fullWidth
                    label={t('addlesson.description', { ns: 'lessons' })}
                    variant="outlined"
                    value={description}
                    onChange={(e) => handleChangeDescription(e)}
                />
                <TextField
                    fullWidth
                    label={t('addlesson.id', { ns: 'lessons' })}
                    variant="outlined"
                    required
                    value={Id}
                    error={!!error_id}
                    onChange={(e) => handleChangeId(e)}
                    helperText={
                        error_id
                            ? t('messages.errorrequired', { ns: 'common' })
                            : ''
                    }
                />
                <Languages
                    setError={setErrorLang}
                    error={error_lang}
                    setValue={setLang}
                    list={['En', 'it-IT']}
                    title={t('addlesson.language', { ns: 'lessons' })}
                />
                <Levels
                    setError={setErrorLevel}
                    error={error_level}
                    setValue={setLevel}
                    list={[1, 2, 3]}
                    title={t('addlesson.level', { ns: 'lessons' })}
                />
                <InputTags
                    error_tags={error_tags}
                    setErrorTags={setErrorTags}
                    setTags={setTags}
                />
                <InputTeachers
                    error_teachers={error_teachers}
                    setErrorTeachers={setErrorTeachers}
                    setTeachers={setTeachers}
                    setSnackBar={setSnackBar}
                />
                <TableDemo
                    t={t}
                    title={t('addlesson.reqtitle', { ns: 'lessons' })}
                    titlefirstcolumn={t('addlesson.prerequisite', {
                        ns: 'lessons',
                    })}
                    titlesecondcolumn={t('addlesson.prerequisiteDesc', {
                        ns: 'lessons',
                    })}
                    firstcolumn="text"
                    secondcolumn="description"
                    setValue={setReq}
                    required={false}
                />{' '}
                {/*checkTable={checkTableReq}*/}
                <TableDemo
                    t={t}
                    title={t('addlesson.urltitle', { ns: 'lessons' })}
                    titlefirstcolumn={t('addlesson.url', { ns: 'lessons' })}
                    titlesecondcolumn={t('addlesson.urlLink', {
                        ns: 'lessons',
                    })}
                    firstcolumn="name"
                    secondcolumn="url"
                    setValue={setLinks}
                    required={false}
                />{' '}
                {/*checkTable={checkTableUrl}*/}
                <SendButton handleSendClick={handleClick} loading={loading} />
            </Stack>
            <BottomSnackBar
                open={open}
                setOpen={setOpen}
                type={type}
                message={message}
            />
        </Box>
    )
}
