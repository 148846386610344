import { Box, Toolbar } from '@mui/material'
import logo from '../assets/img/certbg.png'

export default function Home() {
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                overflowX: 'auto',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Toolbar />
            <Box
                sx={{
                    backgroundImage: `url(${logo})`,
                    backgroundSize: 'cover',
                    height: 500,
                }}
            >
            </Box>
        </Box>
    )
}
