import { useTranslation } from 'react-i18next'

import InputAutocomplete from './AutocompleteBasic'

export default function Languages(props) {
    const [t] = useTranslation('common', { keyPrefix: 'languages' })

    const setLangEnum = (value) => {
        switch (value) {
            case 'En':
                return t('english')
            case 'it-IT':
                return t('italian')
            default:
                console.log('Error: language value not valid')
                break
        }
    }

    return (
        <InputAutocomplete
            defaultValue={props.defaultValue}
            loading={false}
            optionlabel={(option) => setLangEnum(option)}
            required={true}
            multiple={false}
            setErrorRequired={props.setError}
            list_names={props.list}
            freeSolo={false}
            setValue={props.setValue}
            error_required={props.error}
            t={t}
        />
    )
}
