import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'

import { CORSI, MODIFICA_LEZIONE } from '../utils/api'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from '@mui/material'

import Levels from '../components/Level'
import InputTags from '../components/Tags'
import InputTeachers from '../components/Teachers'
import TableDemo from '../components/DynamicTable'

import { useTranslation } from 'react-i18next'
import { isStringCorrect } from '../utils/utils'

export default function EditLessonDialog(props) {
    const { onClose, selectedValue, open, setSnackBar } = props
    const [t] = useTranslation(['common', 'lessons'])
    const [updateServer] = useMutation(MODIFICA_LEZIONE, {
        refetchQueries: [CORSI],
    })
    const [name, setName] = useState(selectedValue.name)
    const [description, setDescription] = useState(selectedValue.description)
    const [level, setLevel] = useState(selectedValue.level)
    const [requirements, setReq] = useState(selectedValue.requirements)
    const [links, setLinks] = useState(selectedValue.usefulLinks)
    const [teachers, setTeachers] = useState(selectedValue.teachers)

    const [errorName, setErrorName] = useState(false)
    const [errorTags, setErrorTags] = useState(false)
    const [errorLevel, setErrorLevel] = useState(false)
    const [errorTeachers, setErrorTeachers] = useState(false)

    useEffect(() => {
        setName(selectedValue.name)
        setDescription(selectedValue.description)
        setLevel(selectedValue.level)
        setReq(selectedValue.requirements)
        setLinks(selectedValue.usefulLinks)
        setTeachers(selectedValue.teachers)
    }, [selectedValue])

    const handleChangeName = (e) => {
        setErrorName(false)
        setName(e.target.value)
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    let tags_local = []

    function setTags(tags) {
        tags_local = tags
    }

    function setEditOK(name) {
        setSnackBar('success', t('editlesson.lessoneditedok', { ns: 'lessons', lesson: name }))
        onClose()
    }

    function setEditKO(name) {
        setSnackBar('error', t('editlesson.lessoneditedko', { ns: 'lessons', lesson: name }))
        onClose()
    }

    function setEditSameName(name) {
        setSnackBar('error', t('editlesson.lessoneditedsamename', { ns: 'lessons', lesson: name }))
        onClose()
    }

    function setEditNotAuthorized(name) {
        setSnackBar('error', t('editlesson.lessoneditednotauthorized', { ns: 'lessons', lesson: name }))
        onClose()
    }

    const handleEdit = async () => {
        if (isStringCorrect(name) &&
            tags_local?.length !== 0 &&
            level &&
            level?.length !== 0 &&
            teachers?.length !== 0
        ) {
            //invio
            const teac = teachers?.map((t) => {
                const { __typename, id, ...newObj } = t //remove useless key/values pairs, we only need name and surname
                return newObj
            })

            try {
                await updateServer({
                    variables: {
                        input: {
                            name: name,
                            description: description,
                            tags: tags_local,
                            id: selectedValue.id,
                            level: String(level),
                            teachers: teac,
                            requirements: requirements
                                .filter((req) => {
                                    return (
                                        req.text !== '' ||
                                        req.description !== ''
                                    )
                                })
                                .map((r) => {
                                    const { __typename, ...newObj } = r
                                    return newObj
                                }), //remove entries if both fields are empty
                            usefulLinks: links
                                .filter((link) => {
                                    return link.name !== '' || link.url !== ''
                                })
                                .map((l) => {
                                    const { __typename, ...newObj } = l
                                    return newObj
                                }), //remove entries if both fields are empty
                        },
                    },
                })
                setEditOK(name)
            } catch (e) {
                console.error(e)
                if (e.message.includes('same name'))
                    setEditSameName()
                else {
                    if (e.message.includes('not authorized'))
                        setEditNotAuthorized()
                    else setEditKO()
                }
            }
        } else {
            if (name?.length === 0) {
                setErrorName(true)
            }
            if (!isStringCorrect(name)) {
                setSnackBar('warning',
                    t('messages.wrongString', {
                        ns: 'common',
                        str: t('addlesson.name', { ns: 'lessons' })
                    })
                )
            }
            if (tags_local?.length === 0) {
                setErrorTags(true)
            }
            if (!level || level?.length === 0) {
                setErrorLevel(true)
            }
            if (!teachers || teachers?.length === 0) {
                setErrorTeachers(true)
            }
        }
    }
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
                {t('editlesson.editlesson', {
                    ns: 'lessons',
                    lesson: selectedValue.name,
                })}
            </DialogTitle>
            <DialogContent>
                <Stack sx={{ alignItems: 'center' }} spacing={2}>
                    <TextField sx={{ display: 'none' }} />
                    <TextField
                        fullWidth
                        label={t('addlesson.name', { ns: 'lessons' })}
                        variant="outlined"
                        required
                        value={name}
                        error={!!errorName}
                        onChange={(e) => handleChangeName(e)}
                        helperText={
                            errorName
                                ? t('messages.errorrequired', { ns: 'common' })
                                : ''
                        }
                    />
                    <TextField
                        fullWidth
                        label={t('addlesson.description', { ns: 'lessons' })}
                        variant="outlined"
                        value={description}
                        onChange={(e) => handleChangeDescription(e)}
                    />
                    <Levels
                        setError={setErrorLevel}
                        error={errorLevel}
                        setValue={setLevel}
                        defaultValue={selectedValue.level}
                        list={[1, 2, 3]} //todo AddLessonToCourse vuole una string ma lesson ha un int ?
                        title={t('addlesson.level', { ns: 'lessons' })}
                    />
                    <InputTags
                        error_tags={errorTags}
                        setErrorTags={setErrorTags}
                        setTags={setTags}
                        defaultValue={selectedValue.tags.map((t) => t.name)}
                    />
                    <InputTeachers
                        error_teachers={errorTeachers}
                        setErrorTeachers={setErrorTeachers}
                        setTeachers={setTeachers}
                        setSnackBar={setSnackBar}
                        defaultValue={selectedValue.teachers}
                    />
                    <TableDemo
                        t={t}
                        title={t('addlesson.reqtitle', { ns: 'lessons' })}
                        titlefirstcolumn={t('addlesson.prerequisite', {
                            ns: 'lessons',
                        })}
                        titlesecondcolumn={t('addlesson.prerequisiteDesc', {
                            ns: 'lessons',
                        })}
                        firstcolumn="text"
                        secondcolumn="description"
                        defaultValue={selectedValue.requirements}
                        setValue={setReq}
                    />
                    <TableDemo
                        t={t}
                        title={t('addlesson.urltitle', { ns: 'lessons' })}
                        titlefirstcolumn={t('addlesson.url', { ns: 'lessons' })}
                        titlesecondcolumn={t('addlesson.urlLink', {
                            ns: 'lessons',
                        })}
                        firstcolumn="name"
                        secondcolumn="url"
                        defaultValue={selectedValue.usefulLinks}
                        setValue={setLinks}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('button.cancel', { ns: 'common' })}
                </Button>
                <Button variant="contained" onClick={handleEdit}>
                    {t('button.modify', { ns: 'common' })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
