import { Fragment, useEffect, useRef, useState, useCallback } from 'react'
import {
    Box,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useAuthToken } from '../utils/auth'
import { getCurrentLocale } from '../utils/locale'
import { VIDEOS_UPLOAD_URI } from '../utils/api'

import {useDropzone} from 'react-dropzone';

import ProgressBar from '../components/LoadingProgressBar'

import ReactPlayer from 'react-player'

import { createHash } from 'crypto'

import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone'
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone'

import { SMALL_SCREEN_BREAKPOINT, useWindowSize } from '../utils/utils'

export default function VideoUplaod(props) {
    const token = useAuthToken()

    const [t] = useTranslation(['common', 'videos'])
    const {
        videoFilePath,
        setVideoFilePath,
        setSnackBar,
        loading,
        setLoading,
        uuid,
        setErrorVideo,
        videoUpload,
        setVideoUpload,
        thumbnailURL,
        name,
    } = props

    const [videoFileObject, setVideoFileObject] = useState(null)
    const [videoFileDuration, setVideoFileDuration] = useState(1)
    const [blob, setBlob] = useState(null)

    const canvasRef = useRef()
    const videoRef = useRef()
    const [dialogOpen, setDialogOpen] = useState(false)

    const [width] = useWindowSize()

    const [progress, setProgress] = useState(0)
    const [thumbnailExists, setThumbnailExists] = useState(
        thumbnailURL !== null
    )
    const [thumbnailInit, setThumbnailInit] = useState(thumbnailURL !== null)

    document.addEventListener("click", handler, true);

    function handler(e) {
        if (loading) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    const handleVideoUpload = async (files) => {
        console.log(files)
        if (files.length !== 0) {
            setProgress(0)
            setErrorVideo(false)
            setVideoFileObject(files[0]) //use this to send video
            setVideoFilePath(URL.createObjectURL(files[0])) //use this to display the video
            setVideoFileDuration(1) //update with fake duration
        }
    }
    function DropZone({ onDrop }) {
            const { getRootProps, getInputProps } =
              useDropzone({
                accept: {
                    'video/mp4': ['.mp4'],
                    'video/mpeg': ['.mpeg'],
                    'video/ogg': ['.ogv'],
                    'video/mp2t' : ['.ts'],
                    'video/webm' : ['.webm'],
                    'video/x-msvideo' : ['.avi']
                  },
                onDrop,
              });
      
        return (
            <Box sx={{ border: 1, width: '100%', borderColor: "lightgray", borderRadius: "5px"}} {...getRootProps({ className: "dropzone" })}>
              <input className="input-zone" {...getInputProps()} sx={{ border: 1, width: '100%'}}/>
              <Box sx={{textAlign: "center"}}>
                  <p className="dropzone-content">
                    {t('addvideo.dropvideo', { ns: 'videos' })}
                  </p>
              </Box>
            </Box>
        );
      }

      const onDrop = useCallback((acceptedFiles) => {
        handleVideoUpload(acceptedFiles)
      }, []);

    useEffect(() => {
        async function sendVideo(uuid) {
            if (videoFileObject === null) {
                 console.log('No changes applied')
                 setLoading(false)
                 setSnackBar('error', t('addvideo.nochanges', { ns: 'videos' }))
            } else {
                let total_size = videoFileObject.size
                let remaining_size = videoFileObject.size
                let index = 0
                let CHUNK_SIZE = 5000000 //5MB
                let i = 0
                let tot = Math.ceil(total_size / CHUNK_SIZE) 

                let videoHeaders = new Headers({
                    Authorization: token,
                    'accept-language': getCurrentLocale().code,
                    ///'X-Content-Duration': videoFileDuration,
                    'Content-Type': videoFileObject.type,
                })

                let hash = createHash('sha1')

                while (remaining_size > 0) {
                    let part = videoFileObject.slice(index, CHUNK_SIZE + index)
                    let res
                    try {
                        hash.update(Buffer.from(await part.arrayBuffer()))
                        res = await fetch(
                            VIDEOS_UPLOAD_URI + uuid + '/part' + i,
                            {
                                method: 'POST',
                                body: part,
                                headers: videoHeaders,
                            }
                        )
                        if (!res.ok) {
                            continue //resend same chunk
                        }
                        remaining_size -= CHUNK_SIZE
                        index += CHUNK_SIZE
                        i++
                        setProgress((i * 100) / tot)
                    } catch (error) {
                        console.error(error)
                        continue
                    }
                }

                hash= hash.digest('hex')
                try {
                    let res = await fetch(
                        VIDEOS_UPLOAD_URI + uuid + '/complete',
                        {
                            method: 'POST',
                            headers: videoHeaders,
                            body: hash,
                        }
                    )
                    if (res.ok) {
                        //Uploaded
                        setLoading(false)
                        setSnackBar(
                            'success',
                            t('addvideo.uploadvideook', { ns: 'videos' })
                        )
                    } else if (res.status === '418') {
                        //Failed
                        setLoading(false)
                        setSnackBar(
                            'error',
                            t('addvideo.retryupload', { ns: 'videos' })
                        )
                    }
                } catch (error) {
                    console.error(error)
                    //Failed
                    setLoading(false)
                    setSnackBar(
                        'error',
                        t('addvideo.retryupload', { ns: 'videos' })
                    )
                }
            }

            if (blob) {
                //TODO mettere una thumbnail di default ?
                let thumbnailHeaders = new Headers({
                    Authorization: token,
                    'accept-language': getCurrentLocale().code,
                    'Content-Type': 'image/thumb',
                })

                try {
                    let res = await fetch(VIDEOS_UPLOAD_URI + uuid, {
                        method: 'POST',
                        body: blob,
                        headers: thumbnailHeaders,
                    })
                    if (res.ok) {
                        setLoading(false)
                        setSnackBar(
                            'success',
                            t('addvideo.uploadthumbnailok', { ns: 'videos' })
                        )
                    } else {
                        setLoading(false)
                        setSnackBar(
                            'error',
                            t('addvideo.retryupload', { ns: 'videos' })
                        )
                    }
                } catch (error) {
                    console.error(error)
                    setLoading(false)
                    setSnackBar(
                        'error',
                        t('addvideo.retryupload', { ns: 'videos' })
                    )
                }
            }

           window.location.reload()
        }

        if (videoUpload) {
            sendVideo(uuid)
            setVideoUpload(false)
        }
        if (thumbnailInit) {
            const image = new Image()
            image.crossOrigin = 'anonymous'
            image.src = thumbnailURL

            image.onload = () => {
                canvasRef.current.width = image.width
                canvasRef.current.height = image.height
                canvasRef.current
                    .getContext('2d')
                    .drawImage(image, 0, 0, image.width, image.height)
            }
        }
    }, [
        videoUpload,
        thumbnailURL,
        thumbnailInit,
        setVideoUpload,
        uuid,
        blob,
        setLoading,
        setSnackBar,
        t,
        token,
        videoFileDuration,
        videoFileObject,
    ])

    if (token === null) {
        return (
            <div className="unauth-container">
                <CircularProgress color="secondary" />
            </div>
        )
    }

    const deleteFile = () => {
        setVideoFilePath(false)
        setBlob(null)
        setVideoFileDuration(0)
        setVideoFileObject(null)
        setThumbnailExists(false)
        setThumbnailInit(false)
    }

    const takeVideoCapture = () => {
        const videoElement = videoRef.current.getInternalPlayer()
        videoRef.current.crossorigin = 'anonymous'
        videoElement.pause()
        canvasRef.current.width = videoElement.videoWidth
        canvasRef.current.height = videoElement.videoHeight
        canvasRef.current
            .getContext('2d')
            .clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
        canvasRef.current
            .getContext('2d')
            .drawImage(
                videoElement,
                0,
                0,
                canvasRef.current.width,
                canvasRef.current.height
            )

        canvasRef.current.toBlob((blob) => {
            setBlob(blob)
            setThumbnailExists(true)
        })
    }

    const uploadImage = (event) => {
        canvasRef.current
            .getContext('2d')
            .clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
        const image = new Image()
        image.src = URL.createObjectURL(event.target.files[0])

        image.onload = () => {
            canvasRef.current.width = image.width
            canvasRef.current.height = image.height
            canvasRef.current
                .getContext('2d')
                .drawImage(image, 0, 0, image.width, image.height)

            canvasRef.current.toBlob((blob) => {
                setBlob(blob)
                setThumbnailExists(true)
            })
        }
    }

    return (
        <Fragment>
            {(!videoFilePath || videoFileDuration === 0) && (
                <DropZone onDrop={onDrop} />
            )}
            {videoFilePath && videoFileDuration > 0 && (
                <Stack spacing={2} direction="column" sx={{ width: '100%' }}>
                    <Typography
                        component="div"
                        variant="caption"
                        sx={{ color: videoFileDuration > 30 ? 'black' : 'red' }}
                    >
                        <Chip
                            variant="outlined"
                            sx={{ alignSelf: 'flex-start' }}
                            label={
                                videoFileObject ? videoFileObject.name : name
                            }
                            onDelete={deleteFile}
                        />{' '}
                        {t('addvideo.videoduration', {
                            ns: 'videos',
                            duration: new Date(videoFileDuration * 1000)
                                .toISOString()
                                .slice(11, 19),
                        })}
                    </Typography>
                    <Stack direction="row">
                        <ReactPlayer
                            controls
                            url={videoFilePath}
                            onDuration={(seconds) => {
                                setVideoFileDuration(seconds)
                            }}
                            config={{
                                file: { attributes: { crossOrigin: 'true' } },
                            }}
                            width="70%"
                            height="100%"
                            ref={videoRef}
                        />
                        <Stack
                            direction="column"
                            sx={{ maxWidth: '30%', paddingLeft: '1%' }}
                        >
                            {!thumbnailExists &&
                                width > SMALL_SCREEN_BREAKPOINT && (
                                    <Box ml={2}>
                                        {t('addvideo.loadimage', {
                                            ns: 'videos',
                                        })}
                                    </Box>
                                )}
                            {!thumbnailExists && (
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ justifyContent: 'space-evenly' }}
                                >
                                    <Tooltip
                                        title={t('addvideo.uploadimagetip', {
                                            ns: 'videos',
                                        })}
                                    >
                                        <IconButton
                                            component="label"
                                            sx={{ maxWidth: '30%' }}
                                            color="primary"
                                            variant="contained"
                                        >
                                            <input
                                                onChange={uploadImage}
                                                type="file"
                                                accept="image/*"
                                                hidden
                                            />
                                            <UploadFileTwoToneIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={t('addvideo.takescreenshottip', {
                                            ns: 'videos',
                                        })}
                                    >
                                        <IconButton
                                            sx={{ maxWidth: '30%' }}
                                            color="primary"
                                            variant="contained"
                                            onClick={takeVideoCapture}
                                        >
                                            <CameraAltTwoToneIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )}
                            <Box sx={{ alignSelf: 'center' }}>
                                {thumbnailExists && (
                                    <Chip
                                        variant="outlined"
                                        sx={{ alignSelf: 'flex-start' }}
                                        label="Thumbnail"
                                        onDelete={() => {
                                            setThumbnailExists(false)
                                            setThumbnailInit(false)
                                            setBlob(null)
                                            canvasRef.current
                                                .getContext('2d')
                                                .clearRect(
                                                    0,
                                                    0,
                                                    canvasRef.current.width,
                                                    canvasRef.current.height
                                                )
                                        }}
                                    />
                                )}
                                <Tooltip
                                    title={
                                        blob
                                            ? t('addvideo.viewthumbnail', {
                                                ns: 'videos',
                                            })
                                            : ''
                                    }
                                >
                                    <canvas
                                        id="canvas"
                                        ref={canvasRef}
                                        style={{ maxWidth: '100%' }}
                                        onClick={() => {
                                            setDialogOpen(true)
                                        }}
                                    ></canvas>
                                </Tooltip>
                            </Box>
                            {blob && (
                                <Dialog
                                    maxWidth="lg"
                                    open={dialogOpen}
                                    onClose={() => setDialogOpen(false)}
                                >
                                    <DialogContent>
                                        <img
                                            width="100%"
                                            src={canvasRef.current.toDataURL()}
                                            alt="thumbnail"
                                            crossOrigin="anonymous"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </DialogContent>
                                </Dialog>
                            )}
                        </Stack>
                    </Stack>
                    {loading && <ProgressBar progress={progress} />}
                </Stack>
            )}
        </Fragment>
    )
}