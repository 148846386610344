import { useState } from 'react'
import { Chip, IconButton, Stack, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { INSEGNANTI } from '../utils/api'

import InputAutocomplete from '../components/AutocompleteBasic'
import TeacherDialog from '../dialogs/AddTeacherDialog'

import PersonAddAlt1TwoToneIcon from '@mui/icons-material/PersonAddAlt1TwoTone'

import { dynamicSort } from '../utils/utils'

export default function InputTeachers(props) {
    const [t] = useTranslation(['common'], { keyPrefix: 'teachers' })

    const [dialogOpen, setDialogOpen] = useState(false)

    const { data, loading } = useQuery(INSEGNANTI, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    function mapTeacher(option) {
        return option.name + ' ' + option.surname
    }

    function isOptionEqualToValue(option, value) {
        return mapTeacher(option) === mapTeacher(value)
    }

    function renderTags(value, getTagProps) {
        return value.map((option, index) => {
            return (
                <div key={'chip' + index}>
                    {
                        <Chip
                            variant="outlined"
                            label={mapTeacher(option)}
                            {...getTagProps({ index })}
                        />
                    }
                </div>
            )
        })
    }

    return (
        <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <InputAutocomplete
                defaultValue={props.defaultValue}
                isOptionEqualToValue={isOptionEqualToValue}
                loading={loading}
                optionlabel={mapTeacher}
                required={true}
                multiple={true}
                setErrorRequired={props.setErrorTeachers}
                list_names={data?.teachers ? data.teachers.sort(dynamicSort('name')) : []}
                freeSolo={false}
                render_Function={renderTags}
                setValue={props.setTeachers}
                error_required={props.error_teachers}
                t={t}
            />
            {/* <InputTeachers error_teachers={error_teachers} setErrorTeachers={setErrorTeachers} setTeachers={setTeachers} />*/}
            <IconButton
                size="large"
                color="primary"
                aria-label="add a teacher"
                onClick={() => {
                    setDialogOpen(true)
                }}
            >
                <Tooltip title="Add a teacher">
                    <PersonAddAlt1TwoToneIcon fontSize="inherit" />
                </Tooltip>
            </IconButton>
            <TeacherDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                setSnackBar={props.setSnackBar}
            />
        </Stack>
    )
}
